const PlaidBankAccountBanner = ({ text, link, onClick, headline = null }) => {
  return (
    <div className='accreditation-verification-banner'>
      <div className='accreditation-verification-banner__copy-container'>
        <span className='b_16_regular m-60'>{headline && <span className='b_16_semibold'>{headline} </span>}{text}</span>
        {link &&
      <span className='b_16_semibold royal nowrap' onClick={onClick}>{link}</span>
        }
      </div>
    </div>
  )
}

export default PlaidBankAccountBanner
