import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Mixpanel } from 'utils'
import Button from 'components/Global/Button'

const CompleteProfileBanner = () => {
  const history = useHistory()
  const { details: { name } } = useSelector((state) => state.investSlice)

  const goToProfile = () => {
    Mixpanel.track('Click Complete Profile Banner on Product Invest Page', { 'Company Name': name })
    history.push('/profile')
  }

  return (
    <Button className='complete-profile-banner-container' onClick={goToProfile} data-testid='complete-profile-banner' ariaLabel='Please complete your investor profile to place an order'>
      <p className='b_16_regular accessibleIndigo'><span className='b_16_semibold'>Welcome to Linqto!</span> Please complete your investor profile to place an order.</p>
      <span className='inline-text-link click-complete-profile'>Complete Profile</span>
    </Button>
  )
}

export default CompleteProfileBanner
