import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'slices/commonSlice'
import parsingInvestorStatus from 'actions/parsing/parsingInvestorStatus.js'
import api from '../apis/private.js'
import axios from 'axios'
import FormData from 'form-data'

const initialState = {
  accreditedAt: '',
  accreditedCountry: '',
  accreditedStatus: '',
  RDocuments: [],
  ODocuments: [],
  selfAccreditation: '',
  uploadingStatus: 'init',
  requestStatus: 'init',
  hasRequestedFinancialAdvisor: false,
  allowDocumentDelete: true,
  crdNumber: 0,
  uploadErr: false,
  isSignedIn: false,
  hasKyc: false,
  isUserProfileComplete: false,
  pageLoading: false,
  loading: false,
  verifiedDocument: '',
  accreditedCountrySource: '',
  accreditationRegion: '',
  isRetailPurchaser: false,
  email: '',
  legalName: '',
  plaidIncomeToken: '',
  plaidAssetsToken: '',
  wasAccredited: null,
  quizQuestions: [],
  initialValues: {
    crdNumber: ''
  },
  assetsAccounts: [],
  isProcessing: false,
  submitAssetsLoading: false,
  deleteAssetsLoading: false,
  scorePercentage: 0,
  hasPassedAccreditationQuiz: false,
  hasFailedQuiz: false,
  documentAccreditationIsProcessing: false,
  canAttestAsAccreditation: false
}

export const getInvestorStatus = createAsyncThunk(
  'getInvestorStatus',
  (refreshStatus = '', { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .get('/page/accreditedInvestorStatus')
      .then((res) => {
        return fulfillWithValue(parsingInvestorStatus(res.data), { refreshStatus })
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const saveSelfAccreditation = createAsyncThunk(
  'saveSelfAccreditation',
  async (formValue, { dispatch, rejectWithValue }) => {
    try {
      await api.post('/page/accreditedInvestorStatus/selfAccreditation', formValue)
    } catch (error) {
      if (error.response) {
        dispatch(handleServerError(error.response.data.error))
      }
      return rejectWithValue(error.response)
    }
  }
)

export const saveSecurityLicense = createAsyncThunk(
  'saveSecurityLicense',
  (formValue, { dispatch, rejectWithValue }) => {
    return api
      .post('/page/accreditedInvestorStatus/securitiesLicense', formValue)
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const uploadDocument = createAsyncThunk(
  'uploadDocument',
  (file, { dispatch, rejectWithValue, fulfillWithValue }) => {
    const data = new FormData()
    data.append('file', file[0])
    data.append('name', file.documentType)

    const config = {
      method: 'post',
      url: `${import.meta.env.VITE_APP_API_URL}/page/accreditedInvestorStatus/documents`,
      data: data,
      headers: { hostname: window.location.hostname }
    }
    if (window.location.hostname === 'localhost') {
      config.headers = { access_token: localStorage.getItem('linqto_token') }
    }
    return axios(config)
      .then((res) => {
        return fulfillWithValue(parsingInvestorStatus(res.data))
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const deleteDocument = createAsyncThunk(
  'deleteDocument',
  (id, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .delete('/page/accreditedInvestorStatus/documents', { data: { id } })
      .then((res) => {})
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const requestReview = createAsyncThunk(
  'requestReview',
  (_, { dispatch, rejectWithValue }) => {
    return api
      .post('/page/accreditedInvestorStatus/documents/requestReview')
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)
export const saveAccreditedInvestorStatusChoice = createAsyncThunk('saveAccreditedInvestorStatusChoice', async (data, { dispatch, rejectWithValue, fulfillWithValue }) => {
  try {
    await api.post('/page/accreditedInvestorStatus/accreditate', data)
  } catch (error) {
    if (error.response) {
      dispatch(handleServerError(error.response.data.error))
    }
    return rejectWithValue(error.response)
  }
})

export const requestFinancialAdvisor = createAsyncThunk(
  'requestFinancialAdvisor',
  (formValue, { dispatch, rejectWithValue }) => {
    return api
      .post('/financialAdvisorRequest', formValue)
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

/**
 * Get token to open Plaid income check
 */
export const getPlaidIncomeToken = createAsyncThunk(
  'getPlaidIncomeToken',
  (_, { dispatch }) => {
    return api
      .get('/page/accreditedInvestorStatus/accreditationLinkToken')
      .then(res => {
        return res.data.linkToken
      })
      .catch(err => {
        console.log(err.response, 'error')
      })
  }
)
/**
 * Get token for Plaid asset check
 */
export const getPlaidAssetsToken = createAsyncThunk(
  'getPlaidAssetsToken',
  (_, { dispatch }) => {
    return api
      .get('/page/accreditedInvestorStatus/assetsAccreditationLinkToken')
      .then(res => {
        return res.data.linkToken
      })
      .catch(err => {
        console.log(err.response, 'error')
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
      })
  }
)
/**
 * Get token to open Plaid income document tocken
 */
export const getPlaidDocumentIncomeToken = createAsyncThunk(
  'getPlaidDocumentIncomeToken',
  (_, { dispatch }) => {
    return api
      .get('/page/accreditedInvestorStatus/documentIncomeAccreditationLinkToken')
      .then(res => {
        return res.data.linkToken
      })
      .catch(err => {
        console.log(err.response, 'error')
      })
  }
)
/**
 * Get quiz to become accredited
 */
export const getAccreditationQuiz = createAsyncThunk(
  'getAccreditationQuiz',
  (_, { rejectWithValue }) => {
    return api
      .get('/page/accreditedInvestorStatus/financialQuiz')
      .then(res => {
        return res.data.quiz.questions
      })
      .catch(err => rejectWithValue(err))
  }
)

/**
 * Score quiz to become accredited
 */
export const scoreAccreditationQuiz = createAsyncThunk(
  'scoreAccreditationQuiz',
  (data, { dispatch }) => {
    return api.post('/page/accreditedInvestorStatus/financialQuiz', data).then(res => {
      return res.data
    })
      .catch(err => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
      })
  }
)

/**
 * Return if user is accredited based off Plaid income check
 */
export const getPlaidIncomeAccreditaiton = createAsyncThunk(
  'getPlaidIncomeAccreditaiton',
  (_, { dispatch, fulfillWithValue }) => {
    return api
      .get('/page/accreditedInvestorStatus/externalBankIncomeAccreditate')
      .then(res => {
        return fulfillWithValue(res.data.wasAccredited)
      })
      .catch(err => {
        console.log(err.response, 'error')
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
      })
  }
)
/**
 * Call when document income linking is complete
 */
export const getDocumentIncomeLinkingComplete = createAsyncThunk(
  'getDocumentIncomeLinkingComplete',
  (_, { dispatch }) => {
    return api
      .get('/page/accreditedInvestorStatus/documentIncomeLinkingComplete')
      .catch(err => {
        console.log(err.response, 'error')
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
      })
  }
)
/**
 * Return if user is accredited based off Plaid income check
 */
export const sendAssetsLinkedAccountInfo = createAsyncThunk(
  'sendAssetsLinkedAccountInfo',
  (metadata, { dispatch, fulfillWithValue }) => {
    return api
      .post('/page/accreditedInvestorStatus/createAssetsAccreditationReport', metadata)
      .then(res => {
        fulfillWithValue()
      })
      .catch(err => {
        console.log(err.response, 'error')
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
      })
  }
)
/**
 * Submit for backend to process assets report
 */
export const submitAssetsAccounts = createAsyncThunk(
  'submitAssetsAccounts',
  (_, { dispatch, fulfillWithValue }) => {
    return api
      .get('/page/accreditedInvestorStatus/assetsLinkingComplete')
      .then(res => {
        fulfillWithValue()
      })
      .catch(err => {
        console.log(err.response, 'error')
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
      })
  }
)
/**
 * Return if user is accredited based off Plaid income check
 */
export const deleteAssetsAccreditation = createAsyncThunk(
  'deleteAssetsAccreditation',
  (_, { dispatch, fulfillWithValue }) => {
    return api
      .delete('/page/accreditedInvestorStatus/deleteAssetsAccreditation')
      .then(res => {
        fulfillWithValue()
      })
      .catch(err => {
        console.log(err.response, 'error')
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
      })
  }
)

export const InvestorStatusSlice = createSlice({
  name: 'investorStatusSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getInvestorStatus.pending, (state, { meta }) => {
        if (meta.arg === 'noRefresh') {
          state.pageLoading = false
        } else {
          state.pageLoading = true
        }
      })
      .addCase(getInvestorStatus.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload,
          initialValues: { crdNumber: payload.crdNumber }
        }
      })
      .addCase(getInvestorStatus.rejected, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(saveSelfAccreditation.pending, (state) => {
        state.loading = true
      })
      .addCase(saveSelfAccreditation.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(saveSelfAccreditation.rejected, (state) => {
        state.loading = false
      })
      .addCase(saveSecurityLicense.pending, (state) => {
        state.loading = true
      })
      .addCase(saveSecurityLicense.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(saveSecurityLicense.rejected, (state) => {
        state.loading = false
      })
      .addCase(requestReview.pending, (state) => {
        state.loading = true
      })
      .addCase(requestReview.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(requestReview.rejected, (state) => {
        state.loading = false
      })
      .addCase(saveAccreditedInvestorStatusChoice.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(saveAccreditedInvestorStatusChoice.fulfilled, (state) => {
        state.pageLoading = false
      })
      .addCase(saveAccreditedInvestorStatusChoice.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(requestFinancialAdvisor.pending, (state) => {
        state.requestStatus = 'loading'
      })
      .addCase(requestFinancialAdvisor.fulfilled, (state) => {
        state.requestStatus = 'success'
      })
      .addCase(requestFinancialAdvisor.rejected, (state) => {
        state.requestStatus = 'failed'
      })
      .addCase(getPlaidIncomeToken.fulfilled, (state, { payload }) => {
        state.plaidIncomeToken = payload
      })
      .addCase(getPlaidAssetsToken.fulfilled, (state, { payload }) => {
        state.plaidAssetsToken = payload
      })
      .addCase(getPlaidDocumentIncomeToken.fulfilled, (state, { payload }) => {
        state.plaidDocumentIncomeToken = payload
      })
      .addCase(getPlaidIncomeAccreditaiton.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getPlaidIncomeAccreditaiton.fulfilled, (state, { payload }) => {
        state.pageLoading = false
        state.wasAccredited = payload
      })
      .addCase(getPlaidIncomeAccreditaiton.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(sendAssetsLinkedAccountInfo.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(sendAssetsLinkedAccountInfo.fulfilled, (state, { payload }) => {
        state.pageLoading = false
      })
      .addCase(sendAssetsLinkedAccountInfo.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(submitAssetsAccounts.pending, (state) => {
        state.submitAssetsLoading = true
      })
      .addCase(submitAssetsAccounts.fulfilled, (state, { payload }) => {
        state.submitAssetsLoading = false
      })
      .addCase(submitAssetsAccounts.rejected, (state) => {
        state.submitAssetsLoading = false
      })
      .addCase(deleteAssetsAccreditation.pending, (state) => {
        state.deleteAssetsLoading = true
      })
      .addCase(deleteAssetsAccreditation.fulfilled, (state, { payload }) => {
        state.deleteAssetsLoading = false
      })
      .addCase(deleteAssetsAccreditation.rejected, (state) => {
        state.deleteAssetsLoading = false
      })
      .addCase(getAccreditationQuiz.fulfilled, (state, { payload }) => {
        state.quizQuestions = payload
      })
      .addCase(getAccreditationQuiz.rejected, (state, { payload }) => {
        state.hasFailedQuiz = true
      })
      .addCase(scoreAccreditationQuiz.fulfilled, (state, { payload }) => {
        state.scorePercentage = payload?.quizScore?.scorePercentage
        state.hasPassedAccreditationQuiz = payload?.quizScore?.hasPassedAccreditationQuiz
      })
  }
})

export default InvestorStatusSlice.reducer
