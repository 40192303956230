import * as yup from 'yup'
import { isWhiteSpace, isURL } from 'utils'
import { isValidPhoneNumber } from 'react-phone-number-input'

export const schema = yup.object().shape({
  firstName: yup
    .string()
    .required('Please enter a first name.')
    .test(
      'WhiteSpace',
      'Please enter a first name.',
      (val) => !isWhiteSpace(val)
    ).test(
      'URL',
      'Please enter a valid first name.',
      (val) => !isURL(val)
    ),
  lastName: yup
    .string()
    .required('Please enter a last name.')
    .test(
      'WhiteSpace',
      'Please enter a last name.',
      (val) => !isWhiteSpace(val)
    ).test(
      'URL',
      'Please enter a valid last name.',
      (val) => !isURL(val)
    ),
  citizenshipCountry: yup
    .string()
    .required('Please enter your country of citizenship.'),
  pStreet1: yup
    .string()
    .required('Please enter your street address.')
    .test(
      'WhiteSpace',
      'Please enter your street address.',
      (val) => !isWhiteSpace(val)
    ),
  pCity: yup
    .string()
    .required('Please enter your city.')
    .test('WhiteSpace', 'Please enter your city.', (val) => !isWhiteSpace(val)),
  pZip: yup
    .string()
    .required('Please enter your postal code.')
    .test(
      'WhiteSpace',
      'Please enter your postal code.',
      (val) => !isWhiteSpace(val)
    ),
  pState: yup
    .string()
    .required('Please select a State or Province.')
    .test(
      'WhiteSpace',
      'Please select a State or Province.',
      (val) => !isWhiteSpace(val)
    ),
  pCountry: yup.string().required('Please select a Country.'),
  mStreet1: yup
    .string()
    .ensure()
    .when('sameAddress', {
      is: true,
      then: yup
        .string()
        .required('Please enter your street address.')
        .test(
          'WhiteSpace',
          'Please enter your street address.',
          (val) => (val) => !isWhiteSpace(val)
        )
    }),
  mCity: yup
    .string()
    .ensure()
    .when('sameAddress', {
      is: true,
      then: yup
        .string()
        .required('Please enter your city.')
        .test(
          'WhiteSpace',
          'Please enter your city.',
          (val) => (val) => !isWhiteSpace(val)
        )
    }),
  mZip: yup
    .string()
    .ensure()
    .when('sameAddress', {
      is: true,
      then: yup
        .string()
        .required('Please enter your postal code.')
        .test(
          'WhiteSpace',
          'Please enter your postal code.',
          (val) => (val) => !isWhiteSpace(val)
        )
    }),
  mState: yup
    .string()
    .ensure()
    .when('sameAddress', {
      is: true,
      then: yup
        .string()
        .required('Please select a State or Province.')
        .test(
          'WhiteSpace',
          'Please select a State or Province.',
          (val) => (val) => !isWhiteSpace(val)
        )
    }),
  mCountry: yup
    .string()
    .ensure()
    .when('sameAddress', {
      is: true,
      then: yup.string().required('Please select a Country.')
    }),
  phone: yup
    .string()
    .ensure()
    .required('Please enter a phone number.')
    .test(
      'validPhone',
      'Please enter a valid phone number.',
      (val) => val && isValidPhoneNumber(val)
    ),
  foreignTaxId: yup
    .string()
    .ensure()
    .when(['pCountry', 'citizenshipCountry'], {
      is: (pCountry, citizenshipCountry) => pCountry !== 'United States' && citizenshipCountry !== 'United States',
      then: yup
        .string()
        .required('Please enter a Foreign Tax ID.')
        .test(
          'WhiteSpace',
          'Please enter a Foreign Tax ID.',
          (val) => (val) => !isWhiteSpace(val)
        )
    }),
  taxId: yup
    .string()
    .ensure()
    .when(['pCountry', 'citizenshipCountry'], {
      is: (pCountry, citizenshipCountry) => pCountry === 'United States' || citizenshipCountry === 'United States',
      then: yup
        .string()
        .required('Please enter a valid tax ID.')
        .test(
          'WhiteSpace',
          'Please enter a valid tax ID.',
          (val) => !isWhiteSpace(val)
        )
        .test(
          'len',
          'Please enter a valid tax ID.',
          (val) => val?.replace(/[_ -]/g, '').length === 9
        )
    }),
  type: yup
    .string()
    .ensure()
    .when('hasEntity', {
      is: true,
      then: yup.string().required('Please select an IRA Type.')
    }),
  trustedContactPhone: yup
    .string()
    .ensure()
    .when('$validateTrustedContact', (validateTrustedContact, schema) => {
      if (validateTrustedContact) {
        return schema.required('Please enter a phone number.')
      }
    })
    .when('$validateTrustedContact', (validateTrustedContact, schema) => {
      if (validateTrustedContact) {
        return schema.test(
          'validPhone',
          'Please enter a valid phone number.',
          (val) => val && isValidPhoneNumber(val)
        )
      }
    }),
  trustedContactFirstName: yup
    .string()
    .ensure()
    .when('$validateTrustedContact', (validateTrustedContact, schema) => {
      if (validateTrustedContact) {
        return schema.required('Please enter a first name.')
      }
    }),
  trustedContactLastName: yup
    .string()
    .ensure()
    .when('$validateTrustedContact', (validateTrustedContact, schema) => {
      if (validateTrustedContact) {
        return schema.required('Please enter a last name.')
      }
    }),
  trustedContactRelationship: yup
    .string()
    .ensure()
    .when('$validateTrustedContact', (validateTrustedContact, schema) => {
      if (validateTrustedContact) {
        return schema.required('Please enter a relationship.')
      }
    }),
  trustedContactStreet1: yup
    .string()
    .ensure()
    .when('$validateTrustedContact', (validateTrustedContact, schema) => {
      if (validateTrustedContact) {
        return schema.required('Please enter a street address.')
      }
    }),
  trustedContactCity: yup
    .string()
    .ensure()
    .when('$validateTrustedContact', (validateTrustedContact, schema) => {
      if (validateTrustedContact) {
        return schema.required('Please enter a city.')
      }
    }),
  trustedContactState: yup
    .string()
    .ensure()
    .when('$validateTrustedContact', (validateTrustedContact, schema) => {
      if (validateTrustedContact) {
        return schema.required('Please select a state or province.')
      }
    }),
  trustedContactCountry: yup
    .string()
    .ensure()
    .when('$validateTrustedContact', (validateTrustedContact, schema) => {
      if (validateTrustedContact) {
        return schema.required('Please select a country.')
      }
    }),
  trustedContactZip: yup
    .string()
    .ensure()
    .when('$validateTrustedContact', (validateTrustedContact, schema) => {
      if (validateTrustedContact) {
        return schema.required('Please enter a postal code.')
      }
    }),
  usResidendcyStatus: yup
    .string()
    .when(['pCountry', 'citizenshipCountry'], {
      is: (pCountry, citizenshipCountry) => pCountry === 'United States' && citizenshipCountry !== 'United States',
      then: yup.string().required('Please select status.').test('value', 'Please select status.', (val) => val !== 'NOT_REQUIRED')
    }),
  occupation: yup
    .string()
    .when('$validateEmployment', (validateEmployment, schema) => {
      if (validateEmployment) {
        return schema.required('Please enter your occupation.')
      }
    }),
  employerName: yup
    .string()
    .when('$validateEmployment', (validateEmployment, schema) => {
      if (validateEmployment) {
        return schema.required('Please enter your employer\'s name.')
      }
    }),
  annualIncome: yup
    .string()
    .ensure()
    .when('$validateFinancialProfile', (validateFinancialProfile, schema) => {
      if (validateFinancialProfile) {
        return schema
          .test('len', 'Please select your annual income.', (val) => val !== 'Please select')
      }
    }),
  networthExcludingResidence: yup
    .string()
    .ensure()
    .when('$validateFinancialProfile', (validateFinancialProfile, schema) => {
      if (validateFinancialProfile) {
        return schema
          .test('len', 'Please select your net worth.', (val) => val !== 'Please select')
      }
    }),
  capitalAllocation: yup
    .string()
    .ensure()
    .when('$validateFinancialProfile', (validateFinancialProfile, schema) => {
      if (validateFinancialProfile) {
        return schema
          .test('len', 'Please select how much you will allocate.', (val) => val !== 'Please select')
      }
    }),
  sectors: yup
    .array()
    .ensure()
    .when('$validateFinancialProfile', (validateFinancialProfile, schema) => {
      if (validateFinancialProfile) {
        return schema
          .test('len', 'Please select a sector.', (val) => {
            return val?.length > 0
          })
      }
    })
})
