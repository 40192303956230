import { useSelector } from 'react-redux'
import Modal from 'components/Global/Modal'
import UpholdFundingMethod from './UpholdFundingMethod'
import WireFundingMethod from './WireFundingMethod'
import PlaidFundingMethod from './PlaidFundingMethod'
import PlaidAccounts from './PlaidAccounts'
import UpholdAccounts from './UpholdAccounts'
import { images } from 'assets'
import { useHistory } from 'react-router-dom'
import { Mixpanel } from 'utils'

const FundingMethodsModal = ({
  hideModal,
  setSelectedAccount,
  insufficientError,
  setInsufficientError,
  setShowWireInstruction,
  connectPlaidAccount,
  entityId,
  type = 'funding'
}) => {
  const {
    fundingFromUpholdAllowed,
    plaidLinkingAllowed,
    pendingCashExternalAccounts,
    approvedCashExternalAccounts,
    hasUphold,
    upholdUnderReview,
    upholdAmlStatus,
    upholdIsApproved,
    upholdCards
  } = useSelector((state) => state.walletSlice)
  const { featureFlags } = useSelector(state => state.userSlice)
  const { CanManuallyCreateWireCashExternalAccount, InstantAchFundingEnabled, AchFundingEnabled, UpholdWithdrawalEnabled } = featureFlags || null
  const history = useHistory()

  const showLinkedUpholdAccounts = type === 'funding' ? fundingFromUpholdAllowed && hasUphold && upholdIsApproved : UpholdWithdrawalEnabled && upholdCards.length > 0
  const showLinkedCashAccounts = ((type === 'funding' && AchFundingEnabled) || type === 'withdraw') && approvedCashExternalAccounts.length > 0
  const showPendingCashAccounts = ((type === 'funding' && AchFundingEnabled) || type === 'withdraw') && pendingCashExternalAccounts.length > 0
  const showUpholdFundingOption = fundingFromUpholdAllowed && (!hasUphold || upholdAmlStatus === 'REJECTED') && type === 'funding' && !upholdUnderReview
  const showAccountSeparator = ((type === 'funding' && AchFundingEnabled && approvedCashExternalAccounts.length) ||
  (type === 'funding' && fundingFromUpholdAllowed && hasUphold) ||
  (type === 'withdraw' && pendingCashExternalAccounts.length > 0))

  const handleOnClick = () => {
    Mixpanel.track('Click Add Account Manually on Withdraw Funds Modal')
    history.push(`/cash-account/add-bank${entityId ? `?entityId=${entityId}` : ''}`)
  }
  return (
    <Modal
      modalHeader={type === 'funding' ? 'Select Funding Method' : 'Select Account'}
      hideModal={hideModal}
      crossToClose
      innerStyle='order-modal'
    >
      {showLinkedUpholdAccounts && (
        // NOTE: Uphold linked state
        <UpholdAccounts
          hideModal={hideModal}
          setSelectedAccount={setSelectedAccount}
          insufficientError={insufficientError}
          setInsufficientError={setInsufficientError}
          setShowWireInstruction={setShowWireInstruction}
          type={type}
        />
      )}
      {showLinkedCashAccounts &&
      <PlaidAccounts
        hideModal={hideModal}
        setSelectedAccount={setSelectedAccount}
        insufficientError={insufficientError}
        setInsufficientError={setInsufficientError}
        setShowWireInstruction={setShowWireInstruction}
        accounts={approvedCashExternalAccounts}
        isWidthdraw = {type === 'withdraw'}/>
      }
      {showAccountSeparator &&
        <div className='accounts-separator' />
      }
      {fundingFromUpholdAllowed && type === 'funding' && upholdUnderReview && (
        // NOTE: Uphold under review state
        <UpholdAccounts
          hideModal={hideModal}
          setSelectedAccount={setSelectedAccount}
          insufficientError={insufficientError}
          setInsufficientError={setInsufficientError}
          setShowWireInstruction={setShowWireInstruction}
          addMargin={pendingCashExternalAccounts.length === 0}
        />
      )}
      {showPendingCashAccounts &&
      <PlaidAccounts
        hideModal={hideModal}
        setSelectedAccount={setSelectedAccount}
        insufficientError={insufficientError}
        setInsufficientError={setInsufficientError}
        setShowWireInstruction={setShowWireInstruction}
        accounts={pendingCashExternalAccounts}
        isWidthdraw ={type === 'withdraw'}/>
      }
      {showUpholdFundingOption && (
        // NOTE: Uphold not linked state
        <UpholdFundingMethod />
      )}
      {AchFundingEnabled && plaidLinkingAllowed && type === 'funding' && (
        <PlaidFundingMethod
          connectPlaidAccount={connectPlaidAccount}
          hideModal={hideModal}
          setShowWireInstruction={setShowWireInstruction}
          setSelectedAccount={setSelectedAccount}
        />
      )}
      {type === 'funding' &&
      <WireFundingMethod
        hideModal={hideModal}
        setSelectedAccount={setSelectedAccount}
        setShowWireInstruction={setShowWireInstruction}
        entityId={entityId}
      />
      }
      {type === 'withdraw' && approvedCashExternalAccounts.length > 0 &&
        <hr className='horizontal-line'/>
      }
      {type === 'withdraw' && CanManuallyCreateWireCashExternalAccount &&
          <div
            className={'order-modal-account-item add-funding-modal-item'}
            onClick={handleOnClick}
          >
            <div>
              <img alt={images['plaid-account-active']} src={images['plaid-account-active']} className='account-icon' />
            </div>
            <div className='b_18_semibold add-funding-modal-item-name'>Bank Account</div>
            <div className='add-funding-modal-item-right'>
              <span className='order-modal-account-desc'>Link an Account. {(InstantAchFundingEnabled && type === 'funding') ? 'Funds are available immediately' : 'Funds are available within 1-3 business days'}</span>
              <img src={images['right-arrow']} alt='Select Bank Account' className='account-arrow' />
            </div>
          </div>
      }
    </Modal>
  )
}

export default FundingMethodsModal
