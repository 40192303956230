import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ConditionalRender, Button } from 'components'
import { Mixpanel } from 'utils'
import { images } from 'assets'

const ProfileEntity = () => {
  const history = useHistory()
  const { userProfile, isAccredited } = useSelector((state) => state.profile)
  const { entities } = userProfile

  const handleAddEntity = () => {
    history.push('/entity')
    Mixpanel.track('Click Add an Entity')
  }

  return (
    <div className='profile-box'>
      <div className='b_22_semibold'>Investment Entities</div>
      <div className='gray3 b_16_regular space-above-xsm'>Fund your Linqto accounts with Corporations (S-, C-, B-), Partnerships, LLCs, Trusts (Family, Revocable, etc.), Solo 401ks, Self-Directed IRAs, Non-Profits, and more.</div>
      <div className='profile-list'>
        <div className='profile-list-header gray3 b_16_semibold'>
          My Entities
        </div>
        <ConditionalRender isVisible={isAccredited}>
          {entities?.length === 0 &&
          <div className='profile-list-empty-state'>
            <div className='b_16_semibold gray5'>Designate where you want to receive your shares in the event of an exit.</div>
            <Button onClick={handleAddEntity} mode='secondary'>Add Entity</Button>
          </div>
          }
          {entities?.length > 0 && <>
            {entities?.map((e, i) => {
              return (
                <div key={`entity-${i}`} className='profile-list-item entity'>
                  <span className='b_16_regular gray5'>{e.name}</span>
                  {e.status === 'PENDING_REVIEW' && <span className='b_14_regular gray4 list-status'>Under Review</span>}
                </div>)
            })}
            <a className='profile-list-item action' onClick={handleAddEntity}>
              <span className='b_16_semibold connectedCobalt list-action'><img alt='Add Entity' src={images['icon-list-add']} />Add Entity</span>
            </a>
          </>}
        </ConditionalRender>
        <ConditionalRender isVisible={!isAccredited}>
          <div className='profile-list-empty-state'>
            <div className='b_16_semibold gray3'>Complete your accreditation verification to be able to add a investment entity</div>
            <Button disabled>Add Entity</Button>
          </div>
        </ConditionalRender>
      </div>
    </div>
  )
}

export default ProfileEntity
