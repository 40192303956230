import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Mixpanel } from 'utils'

const InCompleteAccreditationSection = () => {
  const { details } = useSelector(state => state.investSlice)
  const { isSoldOut, retailPurchaseEnabled } = details
  const { isProfileComplete, isAccredited, isRetailPurchaser } = details?.user || {}
  const { featureFlags: { RetailPurchaseAllowed } } = useSelector(state => state.userSlice)
  const history = useHistory()

  const goToAccreditation = () => {
    Mixpanel.track('Click Not Accredited Link')
    history.push('/investor-status')
  }

  if (!isProfileComplete || isAccredited || isSoldOut) return null
  if (!isAccredited && isRetailPurchaser && RetailPurchaseAllowed && retailPurchaseEnabled) return null
  return (
    <div className='place-order-copy'>
      To place an order for this company, please
      <a className='inline-text-link' onClick={goToAccreditation} data-testid='accreditationLink'> verify your accreditation</a>.</div>
  )
}

export default InCompleteAccreditationSection
