import Button from '../../../Global/Button'
import { useHistory } from 'react-router-dom'

const ResetModalConfirmation = ({ hideModal }) => {
  const history = useHistory()
  return (
    <div className='reset-password-modal'>
      <div className='b_18_regular content'>
        We&apos;ve sent you a link to reset your password.
      </div>
      <div className='b_18_regular content'>
       Didn&apos;t get the email? Make sure that you&apos;ve entered the email address associated with
        your Linqto Account. Allow up to five minutes, then check your spam or junk folder.
      </div>
      <div className='btn-group centered'>
        <Button onClick={hideModal}>
          OK
        </Button>
      </div>
      <div className='b_18_regular space-above-sm'>
        Need help?{' '}
        <a
          onClick={() => history.push('/contact')}
          className='link primary contact-us'
        >
          Contact Support
        </a>
      </div>
    </div>
  )
}

export default ResetModalConfirmation
