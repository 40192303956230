import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Mixpanel, getDomain } from 'utils'

const FooterLinks = ({ isOnAppDomain }) => {
  const { isSignedIn, featureFlags } = useSelector((state) => state.userSlice)
  const { CrsCheckEnabled } = featureFlags || {}
  const supportItem = [
    { route: '/faq', name: 'FAQs', cms: true },
    { route: '/contact', name: 'Contact Us', cms: false },
    { route: '/signup', name: 'Get Started', cms: false }
  ]
  const forInvestorItem = [
    { route: '/products', name: 'Invest', cms: !isSignedIn },
    { route: '/market-insights', name: 'Insights', cms: true },
    { route: '/blog', name: 'Blog', cms: true },
  ]
  const companyItem = [
    { route: '/about', name: 'About Us', cms: true },
    { route: '/team', name: 'Team', cms: true },
    { route: '/partners', name: 'Partners', cms: true },
    { route: '/terms-of-use', name: 'Terms of Use', cms: true },
    { route: '/crs', name: 'Form CRS', cms: true },
    { route: '/privacy', name: 'Privacy', cms: true },
    { route: '/press', name: 'Press', cms: true },
    { route: '/careers', name: 'Careers', cms: true }
  ]
  // when CrsCheckEnabled is false, remove Form CRS from the list. If CrsCheckEnabled is true, show All
  const filteredCompanyItem = companyItem.filter(item => item.route !== '/crs' || CrsCheckEnabled)

  const forEquityHolderItem = [{ route: '/sell', name: 'Sell to Linqto', cms: true }]
  const trackLink = (link) => {
    Mixpanel.track('Click Footer Link', { 'Footer Link': link })
    if (link === 'Invest') {
      localStorage.removeItem('investFeedScroll')
    }
  }

  const renderLinkItem = (route, name, cms) => {
    if (isSignedIn && name === 'Sign up') {
      return
    }
    if (cms && isOnAppDomain) {
      return (
        <div className='item' key={name}>
          <a
            key={`footer-${name}`}
            href={getDomain(route, cms)}
            onClick={() => trackLink(name)}
            className='b_18_regular footer-link'
          >
            {name}
          </a>
        </div>
      )
    } else {
      return (
        <div className='item' key={name}>
          <Link
            to={route}
            className='b_18_regular footer-link'
            onClick={() => trackLink(name)}
          >
            {name}
          </Link>
        </div>
      )
    }
  }
  return (
    <div className='footer-links'>
      <div className='nav-group'>
        <div className='navs'>
          <div className='nav-title'>Support</div>
          {supportItem.map((i) => renderLinkItem(i.route, i.name, i.cms))}
        </div>
        <div className='navs'>
          <div className='nav-title'>For Investors</div>
          {forInvestorItem.map((i) =>
            renderLinkItem(i.route, i.name, i.cms)
          )}
        </div>
      </div>
      <div className='nav-group'>
        <div className='navs'>
          <div className='nav-title'>Company</div>
          {filteredCompanyItem.map((i) => renderLinkItem(i.route, i.name, i.cms))}
        </div>
        <div className='navs'>
          <div className='nav-title'>For Equity Holders</div>
          {forEquityHolderItem.map((i) =>
            renderLinkItem(i.route, i.name, i.cms)
          )}
        </div>
      </div>
    </div>
  )
}

export default FooterLinks
