import { useDispatch } from 'react-redux'
import { seoTitleTemplate, Mixpanel } from 'utils'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from 'schemas/addBankSchema'
import SeoMeta from 'components/Global/SeoMeta'
import FBOAddBankHeader from './partials/FBOAddBankHeader'
import FBOAddBankForm from './partials/FBOAddBankForm'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { createManualCashExternalAccount } from 'slices/walletSlice'
import AddBankErrorModal from './partials/AddBankErrorModal'
import { bankCodes } from './partials/bankCountries'
import { useQuery } from 'hooks/useQuery'

const FBOAddBank = () => {
  const query = useQuery()
  const entityId = query.get('entityId')
  const history = useHistory()
  const dispatch = useDispatch()
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {
      bankCountry: 'United States',
      accountType: 'Checking'
    }
  })
  const [showErrorModal, setErrorModal] = useState(false)

  const onSubmit = async (data) => {
    // Strip empty fields (or empty bsb/sort code) from form object
    const info = Object.fromEntries(Object.entries(data).filter(field => !field.includes('') && !field.includes('_')))
    const form = entityId ? { ...info, entityId } : info
    const bankCountry = Object.keys(bankCodes).find(key => bankCodes[key] === data.bankCountry)
    const res = await dispatch(createManualCashExternalAccount(form))
    if (res?.meta?.requestStatus === 'fulfilled') {
      localStorage.setItem('add-manual-account', true)
      Mixpanel.track('Withdrawal Account Successfully Submitted', { 'Bank Country': bankCountry })
      history.push(`/cash-account/${entityId ? `?entityId=${entityId}` : ''}`)
    } else {
      Mixpanel.track('Error on Add Accounts for Withdrawals Page', {
        'Error Type': res?.payload?.data?.error
      })
      setErrorModal(true)
    }
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Add Account')} />
      <div className='page-container page-with-breadcrumb'>
        <div className='inner-container'>
          <FBOAddBankHeader />
          <div className='add-funds-container'>
            <FormProvider {...methods}>
              <FBOAddBankForm
                onSubmit={onSubmit}
              />
            </FormProvider>
          </div>
        </div>
      </div>
      {showErrorModal && <AddBankErrorModal hideModal={() => setErrorModal(false)} />}
    </>
  )
}
export default FBOAddBank
