import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { schema } from 'schemas/originationDetailSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { PageLoading } from 'components'
import { seoTitleTemplate, checkIfNotNumber, Mixpanel } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import OriginationDetailHeader from './partials/OriginationDetailHeader'
import OriginationDetailCompanyLogo from './partials/OriginationDetailCompanyLogo'
import OriginationDetailCompanyOffer from './partials/OriginationDetailCompanyOffer'
import OriginationDetailCompanyCTA from './partials/OriginationDetailCompanyCTA'
import OriginationDetailCompanyCopy from './partials/OriginationDetailCompanyCopy'
import OriginationNotAvailableModal from './partials/OriginationNotAvailableModal'
import OriginationMultipleOffersModal from './partials/OriginationMultipleOffersModal'
import OriginationFooter from 'components/Origination/partials/OriginationFooter'

import { getOriginationDetail } from 'slices/originationSlice'

const OriginationDetail = ({ match }) => {
  const { pageLoading, company, isOriginationAvailable, hasOpenOrigination } =
    useSelector((state) => state.origination)

  const methods = useForm({ mode: 'onTouched', resolver: yupResolver(schema) })

  const [shareClass, setShareClass] = useState('')
  const [amount, setAmount] = useState(0)
  const [shares, setShares] = useState(0)
  const [optionsStrikePrice, setOptionsStrikePrice] = useState('')
  const [showMultipleOffersModal, setShowMultipleOffersModal] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()
  const companyUrlName = match.params.companyName

  useEffect(() => {
    dispatch(getOriginationDetail(companyUrlName)).then(({ meta, payload }) => {
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('View Originations Company Page', { 'Company Name': payload?.company?.name })
      }
    })
  }, [])

  useEffect(() => {
    setAmount(company?.minShares * company?.sharePrice)
    setShares(company?.minShares)
    setShareClass(company?.shareClasses && company.shareClasses[0])
  }, [company])

  const checkStrikeValue = () => {
    return checkIfNotNumber(optionsStrikePrice)
  }

  const checkIfOption = () => {
    return (
      shareClass === 'OPTIONS_FOR_COMMON_SHARES' ||
      shareClass === 'OPTIONS_FOR_PREFERRED_SHARES'
    )
  }

  const checkErr = () => {
    if (checkIfOption() && checkStrikeValue()) {
      return false
    }
    return true
  }

  const directToSignUp = () => {
    localStorage.setItem('prev-route', window.location.pathname)
    history.push(`/signup?fromOrigination=true&company=${company.urlName}`)
  }

  const submitOffer = () => {
    if (checkErr()) {
      if (hasOpenOrigination) {
        setShowMultipleOffersModal(true)
      } else {
        history.push(
          `/sell/offerconfirmation?companyUrlName=${
            company.urlName
          }&shares=${shares}&shareClass=${shareClass}${
            checkIfOption()
              ? `&optionsStrikePrice=${optionsStrikePrice.replace('$', '')}`
              : ''
          }`
        )
      }
    }
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate(`${company?.name} | Sell`)} />
        <PageLoading />
      </>
    )
  }
  return (
    <>
      <SeoMeta title={seoTitleTemplate(`${company?.name} | Sell`)} />
      {isOriginationAvailable && (
        <div className='origination-container origination-details-container unicorns-details-container'>
          <OriginationDetailHeader />
          <div className='page-container'>
            <div className='inner-container'>
              <div className='sections-container'>
                <OriginationDetailCompanyLogo company={company} />
                <OriginationDetailCompanyCopy
                  company={company}
                  page='detail'
                  checkIfOption={checkIfOption}
                />
                <FormProvider {...methods}>
                  <OriginationDetailCompanyOffer
                    company={company}
                    amount={amount}
                    setAmount={setAmount}
                    shares={shares}
                    setShares={setShares}
                    setShareClass={setShareClass}
                    optionsStrikePrice={optionsStrikePrice}
                    setOptionsStrikePrice={setOptionsStrikePrice}
                    checkIfOption={checkIfOption}
                  />
                  <OriginationDetailCompanyCTA
                    company={company}
                    submitOffer={submitOffer}
                    directToSignUp={directToSignUp}
                    checkStrikeValue={checkStrikeValue}
                    checkIfOption={checkIfOption}
                  />
                </FormProvider>
              </div>
              <div className='content-separator' />
              <OriginationFooter />
            </div>
          </div>
        </div>
      )}
      {!isOriginationAvailable && (
        <div className='page-container'>
          <div className='inner-container'>
            <OriginationNotAvailableModal />
          </div>
        </div>
      )}
      {showMultipleOffersModal && (
        <OriginationMultipleOffersModal
          hideModal={() => setShowMultipleOffersModal(false)}
        />
      )}
    </>
  )
}

export default OriginationDetail
