import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import TextInput from 'components/Global/Inputs/TextInput'
import PasswordInput from 'components/Global/Inputs/PasswordInput'
import { FormProvider, useForm } from 'react-hook-form'
import Button from 'components/Global/Button'
import EmailInput from 'components/Global/Inputs/EmailInput'
import ConditionalRender from 'components/Global/ConditionalRender'
import { schema } from 'schemas/referFriendSignUpSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import SignupTerms from 'components/OnBoarding/SignUp/partials/SignupTerms'
import AccreditationModal from 'components/OnBoarding/SignUp/partials/AccreditationModal'
import Toggle from 'components/Global/Toggle'
import { handleReCaptchaVerify } from 'utils/googleRecaptcha'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Mixpanel } from 'utils'
import { signUp, clearErrorState } from 'slices/userSlice'
import GoogleSignInBtn from 'components/OnBoarding/SignUp/partials/GoogleSignInBtn'
import AppleSignInBtn from 'components/OnBoarding/SignUp/partials/AppleSignInBtn'

const ReferSplashForm = ({ referralCode }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { signUpBonus, accreditationTerms, err } = useSelector((state) => state.userSlice)

  const [submitLoading, setSubmitLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showCompleteForm, setShowCompleteForm] = useState(false)
  const [marketingOptIn, setMarketingOptIn] = useState(false)
  const methods = useForm({ mode: 'onSubmit', resolver: yupResolver(schema) })
  const { handleSubmit } = methods
  const { executeRecaptcha } = useGoogleReCaptcha()

  const onSubmit = async (formValues) => {
    setSubmitLoading(true)
    const token = await handleReCaptchaVerify(executeRecaptcha, 'signUp')
    const mixpanelDistinctId = Mixpanel.getDistinctId()
    let bodyTosend = {
      accreditedCountry: '',
      selfAccreditation: 'UNKNOWN',
      fromOrigination: false,
      redirectUrl: '',
      token,
      externalTrackingId: mixpanelDistinctId,
      marketingOptIn
    }

    bodyTosend = {
      ...formValues,
      ...bodyTosend
    }

    const res = await dispatch(signUp(bodyTosend))
    const { meta, payload } = res

    setSubmitLoading(false)
    if (meta.requestStatus === 'fulfilled') {
      history.push({
        pathname: '/activate',
        state: { email: bodyTosend.email }
      })
    } else {
      const isServerError = payload?.data?.error || ''
      if (isServerError === 'REGISTRATION_EMAIL_ALREADY_ACTIVATED') {
        methods.setError('email')
      }
    }
  }

  const onOAuthSuccess = async (accessToken, type, firstName = '', lastName = '') => {
    dispatch(clearErrorState())
    const token = { type, value: accessToken, firstName, lastName }
    sessionStorage.setItem('oAuthToken', JSON.stringify({ ...token }))
    let url = ''
    if (referralCode) url = `?r=${referralCode}`
    history.push(`/signup/create-account${url}`)
  }

  const toggleMarketing = (e) => {
    setMarketingOptIn(e.target.checked)
  }
  return (
    <>
      {showModal && (
        <AccreditationModal
          hideModal={() => setShowModal(false)}
          accreditationTerms={accreditationTerms}
        />
      )}
      <FormProvider {...methods}>
        <div className='refer-friend-form'>
          <form className='grid' noValidate onSubmit={handleSubmit(onSubmit)}>
            <div className='column sixteen'>
              <EmailInput
                name='email'
                ariaLabel='email'
                disabled={submitLoading}
                autoComplete='do-not-autofill'
                placeholder='Enter your email'
              />
            </div>
            <ConditionalRender isVisible={showCompleteForm}>
              <div className='column eight sm-padding-right'>
                <TextInput
                  name='firstName'
                  placeholder='First Name'
                  ariaLabel='firstName'
                  disabled={submitLoading}
                />
              </div>
              <div className='column eight sm-padding-left'>
                <TextInput
                  name='lastName'
                  placeholder='Last Name'
                  ariaLabel='lastName'
                  disabled={submitLoading}
                />
              </div>
              <div className='column sixteen'>
                <PasswordInput
                  name='password'
                  placeholder='Password'
                  ariaLabel='password-input'
                  disabled={submitLoading}
                  autoComplete='new-password'
                />
              </div>
              <div className='column sixteen'>
                <div className='marketing-switch'>
                  <div className='b_14_regular'>Get special offers, new investments, and market insights delivered to your inbox</div>
                  <Toggle handleChange={toggleMarketing} ariaLabel='toggleMarketingSwitch' />
                </div>
              </div>
              {err && <div className='column sixteen text-error'>
                <span>An account already exists using this email address. Please <a className='b_16_regular' href='/signin'>sign in</a>.</span>
              </div>}
              <div className='column sixteen'>
                <div className='btn-group full-width'>
                  <Button
                    type='submit'
                    loading={submitLoading}
                    disabled={submitLoading}
                    customClass='full-width'
                    mode='secondary'
                    data-testid='submit-btn'
                  >
                  Claim Your ${signUpBonus}
                  </Button>
                </div>
              </div>
              <div className='column sixteen'>
                <SignupTerms isFromOrigination={false} setShowModal={setShowModal} />
              </div>
            </ConditionalRender>
            <ConditionalRender isVisible={!showCompleteForm} className='column sixteen'>
              <div className='btn-group full-width'>
                <Button
                  loading={submitLoading}
                  disabled={submitLoading}
                  customClass='full-width'
                  onClick={() => setShowCompleteForm(true)}
                  mode='secondary'
                  data-testid='show-form-btn'
                >
                Claim Your ${signUpBonus}
                </Button>
              </div>
              <div className='refer-friend-auth'>
                <div className='b_16_semibold gray2 signup-title'>
                  Or sign up and claim with
                </div>
                <div className='refer-friend-auth-cta'>
                  <GoogleSignInBtn
                    copy= 'Sign up with Google'
                    onOAuthSuccess={onOAuthSuccess}
                    type='Signup'
                    style='icon'
                  />
                  <AppleSignInBtn
                    copy= 'Sign up with Apple'
                    onOAuthSuccess={onOAuthSuccess}
                    style='icon'
                  />
                </div>
              </div>
            </ConditionalRender>
          </form>
        </div>
      </FormProvider>
    </>
  )
}

export default ReferSplashForm
