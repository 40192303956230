import useWindowSize from 'hooks/useWindowSize'
import { truncate, transformPromoFlag, MOBILE_WIDTH_SIZE, formatDecimal } from 'utils'

const ProductCard = ({ p, showProductDetails }) => {
  const { width } = useWindowSize()
  const promoStyle = {
    RECENTLY_ADDED: 'recently_added',
    TOP_SELLER: 'top_seller',
    FULLY_SUBSCRIBED: 'sold_out',
    LIMITED_SHARES: 'limited_shares',
    COMING_SOON: 'coming_soon',
    NONE: ''
  }

  const isFullySubscribed = p?.promoFlag === 'FULLY_SUBSCRIBED'
  const displayComingSoon = (p?.promoFlag === 'COMING_SOON' && p?.sharePrice === undefined)
  const showSharePrice = p?.sharePrice && !isFullySubscribed

  return (
    <div className='relative' data-testid={p.name}>
      {p.promoFlag !== 'NONE' && <span className={`flag ${promoStyle[p.promoFlag]}`} data-testid={isFullySubscribed ? 'sold-out' : ''}>{transformPromoFlag(p.promoFlag)}</span> }
      <div className='box product-card' key={p.urlName} onClick ={() => showProductDetails(p.urlName, p.name, p.sharePrice)}>
        <div className='product-title'>
          {width <= MOBILE_WIDTH_SIZE && <span className='heading_8_eyebrow product-title__sector'>{p.vertical}</span>}
          {p.logoUrl && <img className='product-title__icon' alt={p.name} src={p.logoUrl} />}
        </div>
        {width >= MOBILE_WIDTH_SIZE && <div className='product-description'>
          <span className='heading_8_eyebrow product-description__sector'>{p.vertical}</span>
          <span className='product-description__description'>{truncate(p.description, 110)}</span>
        </div>}
        <div className='product-share-container'>
          {isFullySubscribed ? <span className='product-share-container__share-price-copy medium'>{'Sold Out'}</span> : <span className={`b_14_regular product-share-container__share-price${displayComingSoon ? '-copy medium' : ''}`}>{p?.sharePrice ? formatDecimal(p?.sharePrice) : 'Coming Soon'}</span>}
          {showSharePrice ? <span className='product-share-container__share-price-copy'>Share Price</span> : ''}
        </div>
      </div>
    </div>
  )
}

export default ProductCard
