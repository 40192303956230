import { useEffect, useState } from 'react'
import { PageLoading, Button, TopBanner } from 'components'
import { seoTitleTemplate, formatDecimal, Mixpanel, Fullstory } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getRecurringInvestmentDetail } from 'slices/recurringInvestmentSlice'
import moment from 'moment'
import RecurringHistoryList from './partials/RecurringHistoryList'
import { RecurringInvestmentFrequency } from '../data'

const RecurringInvestmentConfirmed = () => {
  const { recurringEventId } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const { accountName, bankName, company, frequency, investmentAmount, nextRecurringInvestmentDate, recurringInvestmentHistory, startDate } = useSelector(state => state.recurringInvestment)

  const [pageLoading, setPageLoading] = useState(true)
  const [showTopBanner, setShowTopBanner] = useState(null)

  const goToPortfolioPage = () => {
    Mixpanel.track('View Portfolio on Investment Scheduled Clicked')
    Fullstory.track('Button', { name: 'View Portfolio on Investment Scheduled' })
    history.push('/portfolio')
  }

  const goToRecurringInvestmentPage = () => {
    Mixpanel.track('Edit on Investment Scheduled Clicked')
    Fullstory.track('Button', { name: 'Edit on Investment Scheduled' })
    history.push(`/recurring-investment/${recurringEventId}`)
  }

  useEffect(() => {
    (async () => {
      const res = await dispatch(getRecurringInvestmentDetail({ recurringEventId }))
      const { meta, payload } = res
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('View Investment Scheduled Page', {
          Company: payload?.company?.name,
          'Max Investment Amount': payload?.investmentAmount,
          Frequency: RecurringInvestmentFrequency[payload?.frequency],
          'Start Date': moment(payload?.startDate).format('MMMM DD,YYYY'),
          'Next Recurring Investment Date': payload?.nextRecurringInvestmentDate
        })
        Fullstory.track('Page View', {
          page_name: 'View Investment Scheduled',
          company: payload?.company?.name,
          max_investment_amount: payload?.investmentAmount,
          frequency: RecurringInvestmentFrequency[payload?.frequency],
          start_date: moment(payload?.startDate).format('MMMM DD,YYYY'),
          next_recurring_investment_date: payload?.nextRecurringInvestmentDate
        })
        if (localStorage.getItem('edit-recurring-investment')) {
          setShowTopBanner({ title: 'Recurring Investment Updated', body: 'Your recurring investment is successfully updated for the future. ' })
          localStorage.removeItem('edit-recurring-investment')
        }
      }
      setPageLoading(false)
    })()
  }, [])

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Investment Scheduled')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Investment Scheduled')} />
      <div className='page-container buy-order-container'>
        <div className='inner-container'>
          {showTopBanner && (
            <TopBanner
              title={showTopBanner.title}
              body={showTopBanner.body}
              hideBanner={() => setShowTopBanner(null)}
              type={showTopBanner.type}
            />
          )}
          <div className='buy-order-header buy-order-header'>
            <h1>Investment Scheduled</h1>
            <div className='buy-order-company-name'>
              <img className='buy-order-company-icon' src={company?.iconUrl} alt={company?.name} />
              <div className='gray5 heading_3' data-testid='companyName'>{company?.name}</div>
            </div>
          </div>
          <div className='order-confirmed-container'>
            <div className='order-confirmed-row'>
              <span>Company</span>
              <span>{company?.name}</span>
            </div>
            <div className='order-confirmed-row'>
              <span>Purchase as</span>
              <span>{accountName}</span>
            </div>
            <div className='order-confirmed-row'>
              <span>Max Investment Amount</span>
              <span>{formatDecimal(investmentAmount)}</span>
            </div>
            <div className='order-confirmed-row'>
              <span>Funding Source</span>
              <span className='text-right'>{bankName}</span>
            </div>
            <div className='order-confirmed-row'>
              <span>Frequency</span>
              <span><span>{RecurringInvestmentFrequency[frequency]} on {frequency === 'MONTHLY' ? moment(startDate).format('Do') : `${moment(startDate).format('dddd')}s` }</span></span>
            </div>
          </div>
          <div className='b_18_regular gray5 order-confirmed-copy' data-testid='nextRecurringCopy'>Your next recurring investment will be initiated <span className='b_18_semibold'>{nextRecurringInvestmentDate}</span>, and shares will become available once payment funds clear. </div>
          <div className='btn-group order-confirmed-cta side-by-side'>
            <Button mode='secondary' onClick={goToRecurringInvestmentPage}>Edit</Button>
            <Button onClick={goToPortfolioPage}>View Portfolio</Button>
          </div>
          <RecurringHistoryList recurringInvestmentHistory={recurringInvestmentHistory} />
        </div>
      </div>
    </>
  )
}

export default RecurringInvestmentConfirmed
