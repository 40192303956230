import { useState } from 'react'
import AccreditationTypesModal from '../partials/AccreditationTypesModal'
import { status } from '../../data'

const AccreditationHeaderRevamp = () => {
  const [showAccreditationTypesModal, setShowAccreditationTypesModal] = useState(false)
  return (
    <>
      <div className='heading_7'>Verify Accreditation</div>
      <div className='b_18_regular space-above-sm'>Regulations require you to be accredited to invest on Linqto. {' '}
        <a className='link primary underlined in-line' onClick={() => setShowAccreditationTypesModal(true)}>Learn more</a> </div>
      {showAccreditationTypesModal &&
        <AccreditationTypesModal
          setShowAccreditationTypesModal={setShowAccreditationTypesModal}
          status={status}
        />
      }
    </>
  )
}

export default AccreditationHeaderRevamp
