import { useHistory } from 'react-router-dom'

const SignInLink = () => {
  const history = useHistory()
  return (
    <span className='account-signin'>
      Already have an account?
      <a onClick={() => history.push('/signin')} className='link primary left-padded'>Sign In</a>
    </span>
  )
}

export default SignInLink
