const AccountFundingBanner = ({ bannerAction, needToCompleteInvestorProfile, needToCompleteFundingStep }) => {
  return (
    <div className='account-funding-banner-container'>
      <div className='account-funding-banner-content' onClick={() => bannerAction('fundingBanner')}>
        <div className='account-funding-banner-copy'>
          <div className='title'>Welcome to Linqto!</div>
          <p className='subtitle'>
            Start investing in 4 steps
          </p>
        </div>
        <div className='account-funding-banner-progress'>
          <div className='progress-bar-border' />
          <ul className='progress-bar'>
            <li className='done'>Sign Up</li>
            <li className={`${needToCompleteInvestorProfile ? 'inProgress' : 'done'}`}>Complete Profile</li>
            <li className={`${!needToCompleteInvestorProfile && !needToCompleteFundingStep ? 'done' : !needToCompleteInvestorProfile && needToCompleteFundingStep ? 'inProgress' : ''}`}>Add Funds</li>
            <li>Place Order</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default AccountFundingBanner
