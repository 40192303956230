import { getDomain } from 'utils'
import { NavLink } from 'react-router-dom'
const NavHeader = ({ setToggleStatus, toggleStatus }) => {
  const isOnAppDomain = window?.location?.hostname?.includes('app')
  return (
    <div className='mobile-nav-container'>
      {isOnAppDomain ? <a
        href={getDomain('/', true)}
        className='logo home'
        onClick={() => setToggleStatus(false)}
      >
        {!toggleStatus && <img alt='Linqto - Private Investing' src='/api/1/logo' />}
      </a> : <NavLink
        to='/'
        className='logo home'
        onClick={() => setToggleStatus(false)}
      >
        {!toggleStatus && <img alt='Linqto - Private Investing' src='/api/1/logo' />}
      </NavLink>}
      <div
        id='nav-icon'
        data-testid='nav-icon'
        className={`toggle-icon ${toggleStatus ? 'open' : ''}`}
        onClick={() => setToggleStatus(!toggleStatus)}
      >
        <span></span>
        <span></span>
      </div>
    </div>
  )
}

export default NavHeader
