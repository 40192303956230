import useWindowSize from 'hooks/useWindowSize'
import { MOBILE_WIDTH_SIZE } from 'utils'

const InvestDetailOrderPill = ({ active, amount, onClick }) => {
  const { width } = useWindowSize()
  const isMobile = width <= MOBILE_WIDTH_SIZE
  return (
    <span
      onClick={onClick}
      className={`${active ? 'active' : ''} invest-order-pill ${isMobile ? 'mobile' : ''} b_14_semibold`}
    >
      {amount}
    </span>
  )
}

export default InvestDetailOrderPill
