import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../apis/private.js'
import axios from 'axios'
import history from 'history.js'
import { resetUserState } from 'slices/userSlice'
const initialState = {
  pageLoading: false,
  title: '',
  dropDownLoading: false,
  uploadLoading: false,
  countries: [],
  regions: [],
  downloadUrl: '',
  isLoggedIn: false,
  submitButtonLoading: false,
  uploadErr: false,
  systemErr: false,
  contentCards: [],
  investContentCards: [],
  portfolioContentCards: [],
  portfolioDetailContentCards: [],
  walletContentCards: [],
  settingsContentCards: [],
  investDetailContentCards: [],
  buyOrderReviewContentCards: [],
  buyOrderConfirmedContentCards: [],
  isMixpanelExist: false,
  faqs: []
}

export const getCountries = createAsyncThunk(
  'getCountries',
  (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .get('/countries')
      .then((res) => {
        if (res && res.data) {
          return fulfillWithValue(res.data)
        }
        return []
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const getRegions = createAsyncThunk(
  'getRegions',
  (country, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .get(`/regions?countryName=${country}`)
      .then((res) => {
        if (res && res.data) {
          return fulfillWithValue(res.data)
        }
        return []
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const uploadDocument = createAsyncThunk(
  'uploadDocument',
  ({ acceptedFile, fileName, type, query, func, entityId = null }, { dispatch, fulfillWithValue, rejectWithValue }) => {
    let apiEndPoint = ''
    switch (type) {
    case 'AI':
      apiEndPoint = `${import.meta.env.VITE_APP_API_URL}/page/accreditedInvestorStatus/documents`
      break
    case 'FA':
      apiEndPoint = `${import.meta.env.VITE_APP_API_URL}/page/financialAdvisor/documents`
      break
    case 'ID':
      apiEndPoint = `${import.meta.env.VITE_APP_API_URL}/page/kycStatus/documents`
      break
    case 'USER_UPLOADED':
      apiEndPoint = `${import.meta.env.VITE_APP_API_URL}/page/userDocuments`
      break
    case 'ENTITY':
      apiEndPoint = `${import.meta.env.VITE_APP_API_URL}/page/entity/document`
      break
    default:
      apiEndPoint = `${import.meta.env.VITE_APP_API_URL}/page/accreditedInvestorStatus/documents`
    }
    const token = localStorage.getItem('linqto_token')
    let headers = type !== 'AI' && query
    if (type === 'AI' && window.location.hostname === 'localhost') {
      headers = { access_token: token }
    }
    const data = new FormData()
    data.append('file', acceptedFile)
    data.append('name', fileName)

    // Upload Documents - Add Entity Page
    if (type === 'ENTITY') {
      headers = { entityId }
    }

    const config = {
      method: 'post',
      url: apiEndPoint,
      headers: { ...headers, hostname: window.location.hostname },
      onUploadProgress: (progressEvent) => {
        func(progressEvent)
      },
      data: data
    }
    return axios(config)
      .then(res => {
        return fulfillWithValue(res.data)
      })
      .catch(err => {
        if (err.response) {
          dispatch(handleServerError('UPLOAD_FAILED'))
          return rejectWithValue(err.response)
        }
      })
  }
)

export const handleServerError = createAsyncThunk('handleServerError', (status, { dispatch, fulfillWithValue, rejectWithValue }) => {
  switch (status) {
  case 'NOT_LOGGED_IN_TOKEN_INVALID':
    if (history.location.pathname !== '/signin') {
      const returnUrl = window.location.href.replace(window.location.origin, '')
      console.log('returnUrl', returnUrl)
      localStorage.setItem('prev-route', returnUrl)
    }
    dispatch(resetUserState())
    localStorage.removeItem('linqto_token')
    history.push('/signin')
    break
  case 'SIGNUP_INCOMPLETE_KYC_REQUIRED':
    history.push('/identity')
    break
  case 'SIGNUP_INCOMPLETE_SELF_ACCREDITATION_REQUIRED':
    history.push('/confirm-investor-status')
    break
  case 'Internal Server Error':
    return rejectWithValue()
  case 'MFA_REQUIRED':
    history.push('/signin/mfas')
    break
  default:
    console.log('[handleServerError]: default', status)
  }
})

export const unsubscribe = createAsyncThunk('unsubscribe', ({ query }, { dispatch, fulfillWithValue, rejectWithValue }) => {
  return api
    .get(`/unsubscribe${query}`)
    .then((res) => {
      return fulfillWithValue()
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return rejectWithValue(err.response)
    })
})

export const getFaqs = createAsyncThunk('getFaqs', async (faqs, { dispatch, _, rejectWithValue }) => {
  try {
    const response = await api.get(`/page/faq/${faqs}`)
    return response?.data?.faqs || []
  } catch (err) {
    if (err.response) {
      dispatch(handleServerError(err.response.data.error))
    }
    return rejectWithValue(err.response)
  }
})

export const commonSlice = createSlice({
  name: 'commonSlice',
  initialState,
  reducers: {
    resetUploadErr: (state) => {
      state.uploadErr = false
    },
    setContentCards: (state, { payload }) => {
      if (payload && payload.length) {
        payload.forEach((card) => {
          if (Object.keys(card.extras)?.length > 0) {
            if (card?.extras?.invest_feed_web) {
              state.investContentCards = [...state.investContentCards, card]
            }
            if (card?.extras?.portfolio_feed_web) {
              state.portfolioContentCards = [...state.portfolioContentCards, card]
            }
            if (card?.extras?.portfolio_detail_feed_web) {
              state.portfolioDetailContentCards = [...state.portfolioDetailContentCards, card]
            }
            if (card?.extras?.wallet_feed_web) {
              state.walletContentCards = [...state.walletContentCards, card]
            }
            if (card?.extras?.settings_feed_web) {
              state.settingsContentCards = [...state.settingsContentCards, card]
            }
            if (card?.extras?.invest_detail_feed_web) {
              const arr = card?.extras?.invest_detail_feed_web?.split(',')
              if (arr && arr?.length) {
                const investDetailContentCardsByCompanyName = arr?.map(name => ({ name, card }))
                state.investDetailContentCards = [...investDetailContentCardsByCompanyName]
              }
            }
            if (card?.extras?.buy_order_review_feed_web) {
              state.buyOrderReviewContentCards = [...state.buyOrderReviewContentCards, card]
            }
            if (card?.extras?.buy_order_confirmed_feed_web) {
              state.buyOrderConfirmedContentCards = [...state.buyOrderConfirmedContentCards, card]
            }
          }
        })
      }
      state.contentCards = [...payload]
    },
    setMixpanelExist: (state) => {
      state.isMixpanelExist = true
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountries.pending, (state) => {
        state.dropDownLoading = true
      })
      .addCase(getCountries.fulfilled, (state, { payload }) => {
        return { ...state, dropDownLoading: false, countries: payload }
      })
      .addCase(getCountries.rejected, (state) => {
        state.dropDownLoading = false
      })
      .addCase(getRegions.pending, (state) => {
        state.dropDownLoading = true
      })
      .addCase(getRegions.fulfilled, (state, { payload }) => {
        return { ...state, dropDownLoading: false, regions: payload }
      })
      .addCase(getRegions.rejected, (state) => {
        state.dropDownLoading = false
      })
      .addCase(uploadDocument.pending, (state) => {
        state.uploadLoading = true
      })
      .addCase(uploadDocument.fulfilled, (state, { payload }) => {
        state.uploadLoading = false
      })
      .addCase(uploadDocument.rejected, (state) => {
        state.uploadLoading = false
        state.uploadErr = true
      })
      .addCase(handleServerError.rejected, (state) => {
        state.systemErr = true
      })
      .addCase(unsubscribe.pending, state => {
        state.pageLoading = true
      })
      .addCase(unsubscribe.fulfilled, state => {
        state.pageLoading = false
      })
      .addCase(unsubscribe.rejected, state => {
        state.pageLoading = false
      })
      .addCase(getFaqs.fulfilled, (state, { payload }) => {
        state.faqs = payload || []
      })
  }
})
export const { resetUploadErr, setContentCards, setMixpanelExist } = commonSlice.actions
export default commonSlice.reducer
