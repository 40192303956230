import { images } from 'assets'

const TrustedContactRemoveLink = ({ handelRemove, deleteLoading }) => {
  return (
    <div className='column eight sixteen-mobile'>
      <a onClick={handelRemove} className={`remove-link b_18_semibold ${deleteLoading ? 'remove-link-disabled' : ''}`}>
        <img alt='Remove' src={!deleteLoading ? images.remove : images.remove_disabled} /> Remove</a>
    </div>
  )
}

export default TrustedContactRemoveLink
