import SignUpForm from './partials/SignUpForm'
import { FormProvider, useForm } from 'react-hook-form'
import SignInOAuthBtns from './partials/SignInOAuthBtns'
import { schema } from 'schemas/signUpSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { images } from 'assets'
import { useState, useEffect } from 'react'
import { ConditionalRender } from 'components'
import LinqtoCarousel from 'components/Global/LinqtoCarousel'
import { useLDFlags } from 'utils/LaunchDarkly'

const variation1CarouselData = [
  {
    id: 1,
    description: '“Linqto offers easy access to a diverse array of private companies, with a user-friendly platform and detailed research for informed investing. Highly recommended for diversifying into private equity.”',
    imageUrl: 'craig',
    name: 'Craig R.',
    copy: 'Member since 2022'
  },
  {
    id: 2,
    description: '"As a part-time angel investor, I value Linqto for its thorough due diligence, saving me precious time. It offers me access to pre-IPO opportunities in sectors I\'m interested in but have limited insight into."',
    imageUrl: 'cal',
    name: 'Cal M.',
    copy: 'Member since 2022'
  },
  {
    id: 3,
    description: '"Linqto has bridged the gap to make private equity more accessible and affordable with low minimums. This is my first time making private investments and Linqto customer support has provided a seamless experience!"',
    imageUrl: 'rebecca',
    name: 'Rebecca W.',
    copy: 'Member since 2023'
  }
]

export const variation2CarouselData = [
  {
    id: 1,
    imageUrl: 'top_company_1'
  },
  {
    id: 2,
    imageUrl: 'top_company_2'
  },
  {
    id: 3,
    imageUrl: 'top_company_3'
  }
]

export const variation4CarouselData = [
  {
    id: 1,
    imageUrl: 'card1',
    title: 'Sign Up Your Way',
    description: 'Simply sign up the way you want to using Google, Apple or your email.'
  },
  {
    id: 2,
    imageUrl: 'card2',
    title: 'Easily Fund Your Wallet',
    description: 'With many ways to add funds to your wallet, we make it simple to be sure you can invest when you want to.'
  },
  {
    id: 3,
    imageUrl: 'card3',
    title: 'Browse Investments',
    description: 'Once signed up, you gain access to comprehensive company data including valuation, funding rounds, leadership, and more.'
  },
  {
    id: 4,
    imageUrl: 'card4',
    title: 'Manage Your Portfolio',
    description: 'Your portfolio will always show you everything you have in your account. '
  },
  {
    id: 5,
    imageUrl: 'card5',
    title: 'Get Liquidity',
    description: "Enjoy peace of mind when you invest, knowing that you don't have to wait for an IPO to get liquidity when you need it (on most popular investments)."
  }
]

const SignUpTest = ({
  oAuthToken,
  onOAuthSuccess,
  onSubmit,
  fromOrigination,
  setShowModal,
  submitLoading
}) => {
  const methods = useForm({ mode: 'onTouched', resolver: yupResolver(schema), context: { fromOrigination, oAuthToken } })
  const { signUpPageTest } = useLDFlags(['signUpPageTest'])
  const [eyebrowText, setEyebrowText] = useState('')
  const [darkBlueText, setDarkBlueText] = useState('')
  const [lightBlueText, setLightBlueText] = useState('')

  useEffect(() => {
    switch (signUpPageTest) {
    case 'variation1':
      setEyebrowText('REAL PEOPLE, REAL EXPERIENCES')
      setDarkBlueText('Hear From')
      setLightBlueText('Linqto Members')
      break
    case 'variation2':
      setEyebrowText('YOUR ACCESS TO PRIVATE INVESTMENT')
      setDarkBlueText('Explore Our')
      setLightBlueText('Top Listings')
      break
    case 'variation3':
      setEyebrowText('JOIN OUR GROWING COMMUNITY')
      setDarkBlueText('Invest Alongside Top')
      setLightBlueText('Silicon Valley VCs')
      break
    case 'variation4':
      setEyebrowText('YOUR ACCESS TO PRIVATE INVESTMENT')
      setDarkBlueText('Meet Our')
      setLightBlueText('Platform')
      break
    }
  }, [signUpPageTest])

  if (signUpPageTest === 'control') {
    return null
  }

  return (
    <div className='test-container'>
      <img src={images['signup-graphic']} className='test-signup-graphic' alt='Sign up' />
      <div className='test-carousel-container'>
        <ConditionalRender isVisible={signUpPageTest !== 'variation2'} style={{ display: 'flex', flexDirection: 'column' }}>
          <p className='eyebrow-heading heading_8_eyebrow gray3'>{eyebrowText}</p>
          <p className='text-heading heading_3 accessibleIndigo'>{darkBlueText}<span className='heading_4 connectedCobalt'>{' '}{lightBlueText}</span></p>
        </ConditionalRender>
        <ConditionalRender isVisible={signUpPageTest === 'variation1'}>
          <LinqtoCarousel data={variation1CarouselData} />
        </ConditionalRender>
        <ConditionalRender isVisible={signUpPageTest === 'variation2'}>
          <div className='variation2'>
            <p className='eyebrow-heading heading_8_eyebrow gray3'>{eyebrowText}</p>
            <p className='text-heading heading_3 accessibleIndigo'>{darkBlueText}<span className='heading_4 connectedCobalt'>{' '}{lightBlueText}</span></p>
            <LinqtoCarousel stackable wide data={variation2CarouselData} />
          </div>
        </ConditionalRender>
        <ConditionalRender isVisible={signUpPageTest === 'variation3'}>
          <div className='variation3'>
            <p className='blue-subheading heading_1 accessibleIndigo flex justify-center'>650K<img src={images['plus-sign-fresh-mint']} className='plus-sign' alt='650K Linqto Members'/></p>
            <p className='gray-subheading b_16_regular gray3 '>Linqto <br />Members</p>
            <p className='blue-subheading heading_1 accessibleIndigo flex justify-center'>$345M<img src={images['plus-sign-fresh-mint']} className='plus-sign' alt='$345M Total Investments'/></p>
            <p className='gray-subheading b_16_regular gray3'>Total <br /></p>
            <p className='blue-subheading heading_1 accessibleIndigo flex justify-center'>50<img src={images['plus-sign-fresh-mint']} className='plus-sign' alt='50 Companies Available'/></p>
            <p className='gray-subheading b_16_regular gray3'>Companies <br />Available</p>
          </div>
        </ConditionalRender>
        <ConditionalRender isVisible={signUpPageTest === 'variation4'}>
          <LinqtoCarousel stackable data={variation4CarouselData} />
        </ConditionalRender>
      </div>
      <div className='test-form-container'>
        <h1 className='create-account-heading heading_7 '>Create An Account</h1>
        <SignInOAuthBtns
          oAuthToken={oAuthToken}
          onOAuthSuccess={onOAuthSuccess}
        />
        <FormProvider {...methods}>
          <SignUpForm
            onSubmit={onSubmit}
            isFromOrigination={fromOrigination}
            setShowModal={setShowModal}
            submitLoading={submitLoading}
            oAuthToken={oAuthToken}
          />
        </FormProvider>
      </div>
    </div>
  )
}

export default SignUpTest
