import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { images } from 'assets'
import { seoTitleTemplate, Mixpanel, Fullstory } from 'utils'
import { registrationEmailDomains } from 'components/data'
import { resendRegistrationEmail } from 'slices/userSlice'
import SeoMeta from 'components/Global/SeoMeta'
import Button from 'components/Global/Button'
import { useQuery } from 'hooks/useQuery'

const ActivateEmail = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const query = useQuery()
  const emailInQuery = query.get('email')
  const { isMixpanelExist } = useSelector((state) => state.commonSlice)
  const registrationEmailAttemptsStorage = JSON.parse(localStorage?.getItem('registrationEmailAttempts')) || null
  const email = location?.state?.email || emailInQuery || null
  const emailDomain = email?.replace(/.*@/, '').split('.')[0] || ''
  const [countdown, setCountdown] = useState(null)
  const [sendCounter, setSendCounter] = useState(registrationEmailAttemptsStorage?.email === email ? registrationEmailAttemptsStorage?.attempts : 0)
  const [showCopy, setShowCopy] = useState(sendCounter > 0)

  useEffect(() => {
    if (isMixpanelExist) {
      Mixpanel.track('View Sign Up Activate Page')
      Fullstory.track('View Sign Up Activate Page')
      if (registrationEmailDomains[emailDomain]) {
        Mixpanel.track('View Go To Email Provider on Sign Up Activate Page', { 'Email domain': registrationEmailDomains[emailDomain]?.label })
      }
    }
  }, [isMixpanelExist])

  useEffect(() => {
    // add custom tracking for google ads
    window.gtag('event', 'conversion', {
      send_to: 'AW-1057610699/IAkiCLztmuUDEMu3p_gD'
    })
  }, [])

  const reSendEmail = () => {
    if (sendCounter < 3) {
      Mixpanel.track('Click Resend on Sign Up Activate Page')
      Fullstory.track('Click Resend on Sign Up Activate Page')
      dispatch(resendRegistrationEmail(email))
      let timeleft = 15
      const interval = setInterval(() => {
        --timeleft
        setCountdown(timeleft)
        if (timeleft === 0) {
          clearInterval(interval)
          setCountdown(null)
          setSendCounter(sendCounter + 1)
        }
        setShowCopy(true)
      }, 1000)
      localStorage.setItem('registrationEmailAttempts', JSON.stringify({ email: email, attempts: sendCounter + 1 }))
    }
  }

  const redirectToEmailDomain = () => {
    Mixpanel.track('Click on Go To Email Provider on Sign Up Activate Page', { 'Email domain': registrationEmailDomains[emailDomain]?.label })
    Fullstory.track('Click on Go To Email Provider on Sign Up Activate Page', { email_domain: registrationEmailDomains[emailDomain]?.label })
    window.open(registrationEmailDomains[emailDomain]?.url)
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Email Activation')} />
      <div className='page-container'>
        <div className='inner-container'>
          <div className='activate-container'>
            <img
              alt='Check your email'
              src={images.activateEmail}
            />
            <h1>Please check your email!</h1>
            <div className='b_18_regular space-above-md space-below-sm' data-testid='activateEmailCopy'>
              We have sent an email to <strong>{email}</strong>. Please verify your email address by clicking the button in your email.  Link expires after 24 hours.
            </div>
            {registrationEmailDomains[emailDomain] &&
            <div className='btn-group centered full-width'>
              <Button
                mode='primary full-width wide-btn'
                onClick={redirectToEmailDomain}
              >Go to {registrationEmailDomains[emailDomain].label}</Button>
            </div>
            }
            {sendCounter < 3 &&
            <div className='b_18_regular space-above-md space-below-sm'>Didn&apos;t receive your activation email?{' '}
              {!countdown && <a className='link primary' onClick={reSendEmail} data-testid='resendLink'>Resend</a>}
              {countdown && <a className='link primary' data-testid='countdownLink'>{countdown}s</a>}
            </div>}
            {showCopy && <div className={`b_18_regular ${sendCounter >= 3 ? 'space-above-md' : ''}`}>We&apos;ve resent an email. Don&apos;t forget to check your Junk/Spam folder!</div>}
          </div>
        </div>
      </div>
    </>
  )
}

export default ActivateEmail
