import { images } from 'assets'

const BundleCounter = ({ type, disabled, onClick }) => {
  const onCounterClick = () => {
    if (disabled) return null
    else onClick()
  }
  let imgSrc = ''
  if (type === 'plus') {
    imgSrc = disabled ? 'whiteplus_disabled' : 'whiteplus'
  } else {
    imgSrc = disabled ? 'whiteminus_disabled' : 'whiteminus'
  }
  return (
    <>
      <div className={`bundle-qty-btn${disabled ? ' bundle-qty-btn_disabled' : ''}`} data-testid={`qty-btn-${type}`} onClick={onCounterClick}>
        <img src={images[imgSrc]} alt='Bundle Qty' />
      </div>
    </>
  )
}

export default BundleCounter
