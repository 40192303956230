
import FooterLogo from './partials/FooterLogo'
import FooterCopy from './partials/FooterCopy'
import FooterLinks from './partials/FooterLinks'

const Footer = () => {
  const isOnAppDomain = window.location.hostname.includes('app')
  return (
    <div id='footer-div' className='footer-wrapper'>
      <div className='inner-container footer-container'>
        <div className='footer-group wide'>
          <FooterLogo isOnAppDomain={isOnAppDomain} />
          <div className='desktop-footer'>
            <FooterCopy />
          </div>
        </div>
        <div className='footer-group'>
          <FooterLinks isOnAppDomain={isOnAppDomain} />
        </div>
        <div className='mobile-footer'>
          <FooterCopy />
        </div>
      </div>
    </div>
  )
}

export default Footer
