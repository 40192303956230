import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { status, selfAccreditationOptions, selfAccreditationTypes, verificationMethodTypes } from '../data'
import { PageLoading, ConditionalRender, Divider } from 'components'
import { seoTitleTemplate, Mixpanel, useLDFlags } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import InvestorStatusHeader from './partials/InvestorStatusHeader'
import AccreditedUserSection from './partials/AccreditedUserSection'
import NotAccreditedUserCopy from './partials/NotAccreditedUserCopy'
import SelfAccreditationSection from './partials/SelfAccreditationSection'
import ChoosePreferredMethodDropdown from './partials/ChoosePreferredMethodDropdown'
import FinancialProfessionalSection from './partials/FinancialProfessionalSection'
import AccreditationTerms from './partials/AccreditationTerms'
import EUResidentsSection from './partials/EUResidentsSection'
import UKResidentsSection from './partials/UKResidentsSection'
import NoRegCountriesSection from './partials/NoRegCountriesSection'
import USCRDNumberSection from './partials/USCRDNumberSection'
import USNetWorthSection from './partials/USNetWorthSection'
import USIndividualIncomeSection from './partials/USIndividualIncomeSection'
import USJointIncomeSection from './partials/USJointIncomeSection'
import OtherRegCountriesSection from './partials/OtherRegCountriesSection'
import CRDSubmitSuccessModal from './partials/CRDSubmitSuccessModal'
import PlaidAssetsVerification from './partials/PlaidAssetsVerification'
import ContactSupport from 'components/Global/ContactSupport'
import { schema } from 'schemas/crdNumberSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import {
  getInvestorStatus,
  saveSelfAccreditation,
  saveSecurityLicense,
  submitAssetsAccounts,
  getPlaidIncomeAccreditaiton
} from 'slices/investorStatusSlice'
import ButtonGroup from './ButtonGroup'
import NotAccreditedSubmitSuccessModal from './partials/NotAccreditedSubmitSuccessModal'
import PlaidBankAccountBanner from './partials/PlaidBankAccountBanner'

const InvestorStatus = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const {
    accreditedCountry,
    accreditedStatus,
    pageLoading,
    hasRequestedFinancialAdvisor,
    hasKyc,
    isUserProfileComplete,
    loading,
    accreditedCountrySource,
    accreditationRegion,
    initialValues,
    assetsAccounts,
    isProcessing,
    wasAccredited,
    documentAccreditationIsProcessing
  } = useSelector((state) => state.investorStatusSlice)
  const methods = useForm({ mode: 'onTouched', resolver: yupResolver(schema) })

  const [showModal, setShowModal] = useState(false)
  const [showAccreditationTypesModal, setShowAccreditationTypesModal] =
    useState(false)
  const [selfAccreditationState, setSelfAccreditationState] = useState('UNKNOWN')
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [submitPlaidBankAccounts, setSubmitPlaidBankAccounts] = useState(false)
  const [didSubmitAccStatus, setDidSubmitAccStatus] = useState(true)
  const [submitAccSuccessModal, setSubmitAccStatusModal] = useState(false)
  const [verificationMethod, setVerificationMethod] = useState('UNKNOWN')

  const showPlaidIncomeFailure = verificationMethod === 'PLAID' && wasAccredited === false

  const { accreditationIncomeFlow } = useLDFlags(['accreditationIncomeFlow'])

  useEffect(() => {
    getInvestorStatusPage()
  }, [])

  useEffect(() => {
    const messageListener = window.addEventListener('message', async (nativeEvent) => {
      try {
        const data = JSON.parse(nativeEvent?.data)
        if (data?.type === 'plaid success') {
          dispatch(getInvestorStatus('noRefresh'))
        } else if (data?.type === 'plaid income success') {
          const { payload } = await dispatch(getPlaidIncomeAccreditaiton())
          if (!payload) {
            Mixpanel.track('Onboarding Failed Accreditation', { 'Accredited Status': '$200k Individual Income for 2 Years', 'Accreditaiton Method': 'Plaid' })
          }
          dispatch(getInvestorStatus('noRefresh'))
        }
      } catch (err) {
        // ignore error intentionally because there are too many error log here.
      }
    })
    return messageListener
  }, [])

  const getInvestorStatusPage = () => {
    dispatch(getInvestorStatus()).then((res) => {
      const data = res.payload
      if (!data?.hasKyc && res?.payload?.isSignedIn) {
        history.push('/profile')
      } else {
        Mixpanel.track('Onboarding View Investor Status', { 'Accredited Country': res?.payload?.accreditedCountry })
      }
      if (data?.assetsAccounts?.length > 0) {
        setVerificationMethod(verificationMethodTypes.PLAID)
      } else if (data?.hasRequestedFinancialAdvisor) {
        setVerificationMethod(verificationMethodTypes.EMAIL_FINANCIAL_ADVISOR)
      } else if (data.accreditedStatus !== 'NOT_VERIFIED') {
        setVerificationMethod(verificationMethodTypes.DOCUMENT_UPLOAD)
      }
      if (data?.selfAccreditation) {
        setSelfAccreditationState(data.selfAccreditation)
      }
      /* If loaded in react native webview, post message
         so webview can remove required elements from DOM */
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage('pageLoaded')
      }
    })
  }

  useEffect(() => {
    methods.reset(initialValues)
  }, [initialValues])

  useEffect(() => {
    /* For the react native webview, since the header has already
      rendered, this needs to be removed here and not in React Naive code */
    if (window.ReactNativeWebView) {
      document.getElementById('header-div').style.display = 'none'
    }
  }, [])

  const handleRequest = (e) => {
    e.preventDefault()
    setShowModal(true)
    if (hasRequestedFinancialAdvisor) {
      Mixpanel.track('Onboarding Click Resend Financial Professional')
    } else {
      Mixpanel.track('Onboarding Click Email Financial Professional')
    }
  }

  const onSubmit = async (formValues) => {
    const crdNumber = formValues.crdNumber
    if (crdNumber || crdNumber.length > 0) {
      dispatch(saveSecurityLicense({ crdNumber })).then(({ meta }) => {
        if (meta.requestStatus === 'fulfilled') {
          Mixpanel.track('Onboarding Click Submit Verification', {
            'Accreditation Method': 'Manual',
            'Accredited Country': accreditedCountry,
            'Accreditation Status': selfAccreditationOptions[selfAccreditationState]
          })
          getInvestorStatusPage()
          setSubmitSuccess(true)
        }
      })
    }
  }

  const handleSaveSelfAccreditation = async (val) => {
    setSelfAccreditationState(val)
    setDidSubmitAccStatus(false)
    await dispatch(
      saveSelfAccreditation({
        selfAccreditation: val
      })
    )
    setVerificationMethod('UNKNOWN')
  }

  const handleNonAccreditedSubmit = async () => {
    await dispatch(getInvestorStatus())
    setSubmitAccStatusModal(true)
    setDidSubmitAccStatus(true)
    Mixpanel.track('Onboarding Click Submit Verification', {
      'Accreditation Method': 'Auto',
      'Accredited Country': accreditedCountry,
      'Accreditation Status': selfAccreditationOptions[selfAccreditationState]
    })
  }

  const handleSubmitAccSuccessModal = () => {
    setSubmitAccStatusModal(false)
    history.push('/cash-account')
  }

  const handleSubmitPlaid = async () => {
    // submit multiple account API
    await dispatch(submitAssetsAccounts())
    dispatch(getInvestorStatus())
    setSubmitPlaidBankAccounts(true)
  }

  const handleBannerClick = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('navigateToInvest')
    } else {
      history.push('/products')
    }
  }

  const isAccreditedUser = () => accreditedStatus === 'ACCREDITED'

  let accRegionComponent = null

  if (accreditationRegion === 'EU_SELF') {
    accRegionComponent = <EUResidentsSection />
  } else if (accreditationRegion === 'UK_SELF') {
    accRegionComponent = <UKResidentsSection />
  } else if (accreditationRegion === 'OTHER_NOT_REGULATED') {
    accRegionComponent = <NoRegCountriesSection />
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Accredited Investor')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Accredited Investor')} />
      <div className='page-container investor-status-container'>
        <div className='inner-container'>
          {submitPlaidBankAccounts && <PlaidBankAccountBanner text='Your accreditation verification has been submitted. We will send you an email update in a few minutes.' link='Explore Investments' onClick={handleBannerClick} />}
          {showPlaidIncomeFailure && <PlaidBankAccountBanner text='We were unable to confirm your $200k accreditation status via your chosen bank. Please use a different method.' />}
          {documentAccreditationIsProcessing && <PlaidBankAccountBanner headline='Documents Under Review.' text='We will notify you via email once your accreditation verification is complete.' link='Explore Investments' onClick={handleBannerClick}/>}
          <>
            <InvestorStatusHeader
              isUserProfileComplete={isUserProfileComplete}
              hasKyc={hasKyc}
              isAccredited={isAccreditedUser}
              accreditedStatus={accreditedStatus}
            />
            <ConditionalRender isVisible={isAccreditedUser()}>
              <AccreditedUserSection />
            </ConditionalRender>
            <ConditionalRender isVisible={!isAccreditedUser()}>
              <NotAccreditedUserCopy
                setShowAccreditationTypesModal={setShowAccreditationTypesModal}
                showAccreditationTypesModal={showAccreditationTypesModal}
                status={status}
                accreditationRegion={accreditationRegion}
              />
              <ConditionalRender isVisible={accreditationRegion === 'NONE'}>
                <OtherRegCountriesSection />
                <Divider content='OR' customClass='request-container-divider' />
                <FinancialProfessionalSection
                  setShowModal={setShowModal}
                  showModal={showModal}
                />
              </ConditionalRender>
              <ConditionalRender isVisible={accreditationRegion === 'EU_SELF' || accreditationRegion === 'UK_SELF' || accreditationRegion === 'OTHER_NOT_REGULATED'}>
                {accRegionComponent}
              </ConditionalRender>
              <ConditionalRender isVisible={accreditationRegion === 'USA'}>
                <SelfAccreditationSection
                  selfAccreditationState={selfAccreditationState}
                  handleSaveSelfAccreditation={handleSaveSelfAccreditation}
                  loading={loading}
                  accreditedStatus={accreditedStatus}
                />
                <ConditionalRender isVisible={selfAccreditationState === selfAccreditationTypes.NET_WORTH || (selfAccreditationState === selfAccreditationTypes.ANNUAL_INCOME) || selfAccreditationState === selfAccreditationTypes.JOINT_INCOME}>
                  <ChoosePreferredMethodDropdown disabled={isProcessing || documentAccreditationIsProcessing} verificationMethod={verificationMethod} setVerificationMethod={setVerificationMethod} selfAccreditationState={selfAccreditationState}/>
                  <ConditionalRender isVisible={verificationMethod === verificationMethodTypes.PLAID}>
                    <ConditionalRender isVisible={selfAccreditationState === selfAccreditationTypes.ANNUAL_INCOME}>
                      <ButtonGroup disabled={selfAccreditationState === selfAccreditationTypes.UNKNOWN} loading={false} submitButtonType='plaidIncome'/>
                    </ConditionalRender>
                    <ConditionalRender isVisible={selfAccreditationState === selfAccreditationTypes.JOINT_INCOME}>
                      <ButtonGroup disabled={selfAccreditationState === selfAccreditationTypes.UNKNOWN} loading={false} />
                    </ConditionalRender>
                    <ConditionalRender isVisible={selfAccreditationState === selfAccreditationTypes.NET_WORTH}>
                      <ConditionalRender isVisible={verificationMethod === verificationMethodTypes.PLAID}>
                        <PlaidAssetsVerification />
                      </ConditionalRender>
                      <ButtonGroup disabled={assetsAccounts?.length === 0 || isProcessing} loading={false} onClick={handleSubmitPlaid} />
                    </ConditionalRender>
                  </ConditionalRender>
                  <ConditionalRender isVisible={verificationMethod === verificationMethodTypes.DOCUMENT_UPLOAD}>
                    <ConditionalRender isVisible={selfAccreditationState === selfAccreditationTypes.NET_WORTH}>
                      <USNetWorthSection />
                    </ConditionalRender>
                    <ConditionalRender isVisible={selfAccreditationState === selfAccreditationTypes.ANNUAL_INCOME && !accreditationIncomeFlow}>
                      <USIndividualIncomeSection />
                    </ConditionalRender>
                    <ConditionalRender isVisible={selfAccreditationState === selfAccreditationTypes.ANNUAL_INCOME && accreditationIncomeFlow}>
                      <ButtonGroup loading={loading} disabled={loading || isProcessing} submitButtonType='plaidDocumentIncome'/>
                    </ConditionalRender>
                    <ConditionalRender isVisible={selfAccreditationState === selfAccreditationTypes.JOINT_INCOME}>
                      <USJointIncomeSection />
                    </ConditionalRender>
                  </ConditionalRender>
                  <ConditionalRender isVisible={verificationMethod === verificationMethodTypes.EMAIL_FINANCIAL_ADVISOR}>
                    <FinancialProfessionalSection
                      setShowModal={setShowModal}
                      showModal={showModal}
                    />
                    <ButtonGroup disabled={false} loading={false} onClick={handleRequest} submitButtonType='financialAdvisor'/>
                  </ConditionalRender>
                </ConditionalRender>
                <ConditionalRender
                  isVisible={selfAccreditationState === selfAccreditationTypes.SECURITIES_LICENSE}
                >
                  <FormProvider {...methods}>
                    <USCRDNumberSection
                      accreditedStatus={accreditedStatus}
                      loading={loading}
                      onSubmit={onSubmit}
                    />
                  </FormProvider>
                </ConditionalRender>
                <ConditionalRender isVisible={selfAccreditationState === selfAccreditationTypes.NOT_ACCREDITED}>
                  <div className='b_18_regular accreditation-terms margin'>
                    In order to comply with regulations, some or all investment opportunities may not be available to non-accredited investors. If you meet the qualifications in the future, you can update your accreditation status in your investor profile.
                  </div>
                  <div className='b_18_regular accreditation-terms'>
                    <ButtonGroup disabled={didSubmitAccStatus && selfAccreditationState !== selfAccreditationTypes.FINANCIAL_SOPHISTICATION} loading={false} onClick={handleNonAccreditedSubmit} />
                  </div>
                </ConditionalRender>
                <ConditionalRender isVisible={selfAccreditationState === selfAccreditationTypes.FINANCIAL_SOPHISTICATION}>
                  <div className='b_18_regular accreditation-terms'>
                    <ButtonGroup disabled={didSubmitAccStatus && selfAccreditationState !== selfAccreditationTypes.FINANCIAL_SOPHISTICATION} loading={false} onClick={() => history.push('/investor-status/financial-sophisticated-quiz')} submitButtonType='quiz'/>
                  </div>
                </ConditionalRender>
                <ConditionalRender isVisible={selfAccreditationState === selfAccreditationTypes.UNKNOWN || (verificationMethod === verificationMethodTypes.UNKNOWN && selfAccreditationState !== selfAccreditationTypes.FINANCIAL_SOPHISTICATION && selfAccreditationState !== selfAccreditationTypes.NOT_ACCREDITED && selfAccreditationState !== selfAccreditationTypes.SECURITIES_LICENSE)}>
                  <ButtonGroup disabled={selfAccreditationState === selfAccreditationTypes.UNKNOWN || verificationMethod === verificationMethodTypes.UNKNOWN} loading={false}/>
                </ConditionalRender>
              </ConditionalRender>
              <AccreditationTerms accreditedCountry={accreditedCountry} accreditedCountrySource={accreditedCountrySource} />
            </ConditionalRender>
            <ContactSupport alignment='left' onClick={() => Mixpanel.track('Onboarding Click Contact Support')} />
          </>
        </div>
      </div>
      {submitAccSuccessModal && <NotAccreditedSubmitSuccessModal hideModal={handleSubmitAccSuccessModal} />}
      {submitSuccess && <CRDSubmitSuccessModal hideModal={() => setSubmitSuccess(false)} />}
    </>
  )
}

export default InvestorStatus
