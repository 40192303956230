import { useDispatch, useSelector } from 'react-redux'
import { deleteAssetsAccreditation, getInvestorStatus } from 'slices/investorStatusSlice'
import { images } from 'assets'
import { useEffect } from 'react'
import PlaidAssetsLink from './PlaidAssetsLink'
import { Mixpanel } from 'utils'

const PlaidAssetsVerification = () => {
  const dispatch = useDispatch()
  const { assetsAccounts, deleteAssetsLoading, isProcessing } = useSelector((state) => state.investorStatusSlice)
  const disabled = deleteAssetsLoading || isProcessing

  useEffect(() => {
    // refresh page when linked accounts are removed
  }, [deleteAssetsLoading])

  const handleRemoveAccounts = async () => {
    Mixpanel.track('Click Remove Connected Banks on Accreditation Page')
    await dispatch(deleteAssetsAccreditation())
    dispatch(getInvestorStatus('noRefresh'))
  }

  return (
    <div className='verification-section'>
      <div className='heading_7'>Connected Bank Accounts</div>
      <p className='b_18_regular'>Please connect the bank accounts necessary to validate the accreditation status chosen above. When you are done connecting all of your bank accounts, please click “submit” below.</p>
      {assetsAccounts?.length > 0 && <a className={`remove-account-btn link ${disabled ? 'gray' : 'primary'} ${disabled ? 'disabled' : ''}`} onClick={handleRemoveAccounts} >
        <img className='trashcan' src={disabled ? images['trashcan-gray'] : images.trashcan} alt='Delete Account' />
      Remove connected banks
      </a>}
      <div>{assetsAccounts && assetsAccounts.map(assetAccount =>
        <div className='connected-bank-account b_18_semibold' key={assetAccount.accountMask}>
          <img className='bank-icon' src={disabled ? images['plaid-account-pending'] : images['plaid-account-active']} alt={assetAccount.institutionName} />
          {assetAccount.institutionName} (*{assetAccount.accountMask})
        </div>)}
      </div>
      <PlaidAssetsLink hasLinked={assetsAccounts?.length > 0}/>
    </div>
  )
}

export default PlaidAssetsVerification
