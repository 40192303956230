import { useState } from 'react'
import ConditionalRender from 'components/Global/ConditionalRender'
import { images } from 'assets'
import FundingMethodsModal from './FundingMethodsModal'
import { formatDecimal } from 'utils'
import { useFormContext } from 'react-hook-form'

const FBOAddFundsFundingSource = ({
  selectedAccount,
  setSelectedAccount,
  insufficientError,
  setInsufficientError,
  setShowWireInstruction,
  connectPlaidAccount,
  entityId,
  type,
  label,
  testId = ''
}) => {
  const [showFundingMethodsModal, setShowFundingMethodsModal] = useState(false)
  const openFundingMethodsModal = () => {
    setShowFundingMethodsModal(true)
  }

  const { formState: { errors } } = useFormContext()

  const hideModal = () => {
    setShowFundingMethodsModal(false)
  }

  const RenderIntitalSource = () => {
    return (
      <>
        <div className={`b_16_semibold funding-source ${errors?.account ? 'error' : ''}`} onClick={openFundingMethodsModal} data-testid={testId}>
          Select account
          <img
            className='current-investment-item_right tab-arrow'
            src={images['right-arrow']}
            alt='Select account'
          />
        </div>
        {errors?.account && <div className='input-group'>
          <div className='err-msg'>{errors?.account.message}</div>
        </div>
        }
      </>
    )
  }
  const RenderSelectedAccount = () => {
    return (
      <div
        className='funding-source funding-source-account'
        onClick={openFundingMethodsModal}
        data-testid={testId}
      >
        <div>
          <img
            alt={selectedAccount.label}
            src={selectedAccount.iconUrl}
            className='account-icon'
          />
          {selectedAccount.label}
        </div>
        <div>
          {selectedAccount?.usdBalance && (
            <span className='account-balance'>
              {formatDecimal(selectedAccount.usdBalance)}
            </span>
          )}
          <img
            className='current-investment-item_right tab-arrow'
            src={images['right-arrow']}
            alt={selectedAccount.label}
          />
        </div>
      </div>
    )
  }

  return (
    <div className='input-group'>
      <label>{label}</label>
      <ConditionalRender isVisible={!selectedAccount}>
        <RenderIntitalSource />
      </ConditionalRender>
      <ConditionalRender isVisible={!!selectedAccount}>
        <RenderSelectedAccount />
      </ConditionalRender>
      <ConditionalRender isVisible={!!showFundingMethodsModal}>
        <FundingMethodsModal
          hideModal={hideModal}
          setSelectedAccount={setSelectedAccount}
          insufficientError={insufficientError}
          setInsufficientError={setInsufficientError}
          setShowWireInstruction={setShowWireInstruction}
          connectPlaidAccount={connectPlaidAccount}
          entityId={entityId}
          type={type}
        />
      </ConditionalRender>
    </div>
  )
}

export default FBOAddFundsFundingSource
