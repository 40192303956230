import { Wrapper } from 'components'
import InvestDetailValuationRounds from './InvestDetailValuationRounds'
import InvestDetailValuationOverTime from './InvestDetailValuationOverTime'
import InvestDetailValuationCompetitive from './InvestDetailValuationCompetitive'
import { useSelector } from 'react-redux'
// import { images } from 'assets'
import InformationContainer from './InformationContainer'

const InvestDetailValuation = () => {
  const { details } = useSelector(state => state.investSlice)
  const { isSignedIn } = useSelector(state => state.userSlice)
  return (
    <Wrapper className={`invest-tab ${!isSignedIn ? 'blur-info' : ''}`} id='valuation'>
      <div className='grid outer-container'>
        <div className='section-seprator' />
        {details.valuationOverTime && details.valuationOverTime.length > 0 &&
        <div className='valuation-over-time container-margin'>
          <h1 className='column accessibleIndigo'>Valuation</h1>
          <div className='valuation-over-time__container'>
            <div className='column eight row'>
              <div className='subheader-title subheader-extra-margin accessibleIndigo'>
                <h6>Valuation Over Time</h6>
              </div>
              <InvestDetailValuationOverTime valuationOverTime={details.valuationOverTime} />
            </div>
            <InformationContainer />
          </div>
        </div>
        }
        <div className='column sixteen row outer-container container-margin'>
          <h6 className='subheader-title accessibleIndigo'>Funding Rounds</h6>
          <InvestDetailValuationRounds fundingRounds={details.fundingRounds} />
        </div>
        <div className='sixteen column row outer-container container-margin'>
          {details.competitors && details.competitors.length > 0 &&
          <>
            <h6 className='subheader-title accessibleIndigo'>Competitive Landscape</h6>
            <InvestDetailValuationCompetitive competitors={details.competitors} />
          </>}
        </div>
      </div>
    </Wrapper>
  )
}
export default InvestDetailValuation
