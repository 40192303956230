import { useHistory } from 'react-router-dom'

const ContactSupport = ({ alignment = 'right', onClick = () => {} }) => {
  const history = useHistory()
  const redirectToContact = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('navigateToContact')
    } else {
      onClick()
      history.push('/contact')
    }
  }

  return (
    <div
      className={`contact-support-container b_18_regular ${
        alignment === 'right' ? 'right-aligned' : 'left-aligned'
      }`}
    >
      Need help?{' '}
      <span className='inline-text-link' onClick={redirectToContact} target='_blank'>
        Contact Us
      </span>
    </div>
  )
}

export default ContactSupport
