import { useState } from 'react'
import UpholdWalletAccountsItem from './UpholdWalletAccountsItem'
import { images } from 'assets'
import { Mixpanel } from 'utils'
import { useSelector } from 'react-redux'

const UpholdWalletAccounts = () => {
  const [expand, setExpand] = useState(false)
  const { upholdCards } = useSelector((state) => state.walletSlice)
  const handleExpand = () => {
    setExpand(!expand)
    Mixpanel.track('Expand/Collapse Uphold Accounts', { Type: !expand ? 'Expand' : 'Collapse' })
  }
  return (
    <div className='uphold-wallet-accounts'>
      <div className='cheveron-row uphold-wallet-account-row'>
        <div className='pointer' height={41} onClick={handleExpand}>
          <div className='b_16_regular gray4'>View Balances</div>
          {expand ? <img alt='usd' src={images['chevron-up']} height={25} /> : <img alt='usd' src={images['chevron-down']} height={25} />}
        </div>
      </div>
      {expand && upholdCards?.map((account) => (
        <UpholdWalletAccountsItem key={account.id} account={account} />
      ))}
    </div>
  )
}

export default UpholdWalletAccounts
