import { Wrapper, Row, Content, Image } from 'components'
import {
  cleanSeriesName,
  formatDecimal,
  formatWholeNumber,
  toCamelCase,
  formatDate,
  MOBILE_WIDTH_SIZE,
  toCamalCase
} from 'utils'
import StatusTag from './partials/StatusTag'
import ProductDocuments from './partials/ProductDocuments'
import useWindowSize from 'hooks/useWindowSize'
const OrderSection = ({
  isOrderConfirm,
  children,
  company,
  order,
  documents,
  orderId,
  status
}) => {
  const { width } = useWindowSize()
  return (
    <Wrapper className='order-group'>
      <Content className='product-header'>
        <Image
          alt={`Linqto ${company.name} series`}
          src={company.iconUrl}
          className='icon'
        ></Image>
        <h1 className='product-name'>{company.name}</h1>
        {!isOrderConfirm && <div className='heading_7'>Series {cleanSeriesName(order.seriesName)}</div>}
        {isOrderConfirm && (
          <h3 className='price'>
            {formatDecimal(order.sharePrice)}{' '}
            <span className='b_18_regular'>
              per Share
            </span>
          </h3>
        )}
        {width > MOBILE_WIDTH_SIZE && <ProductDocuments documents={documents} />}
      </Content>
      <Content className='product-order box'>
        {isOrderConfirm ? (
          <>
            <div className='buy-product'>
              <h3 className='title'>{"Let's review your investment."}</h3>
              <div className='b_18_regular'>
                You&apos;re buying {formatWholeNumber(order.shares)} shares of{' '}
                {company.name} at approximately {formatDecimal(order.sharePrice)} {' '}
                per share.
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='buy-product'>
              <h3 className='title'><span>Order {orderId}</span> {status && <StatusTag status={status.toUpperCase()}/>}</h3>
              <div className='b_18_regular'>
                This order includes {formatWholeNumber(order.shares)} shares of{' '}
                {company.name} at {formatDecimal(order.sharePrice)}{' '}
                per share.
              </div>
            </div>
          </>
        )}
        <div className='place-order-box review'>
          <Row className='place-order-box__details'>
            <div>Payment Method</div>
            <div>{order.total === 0 ? order.promotionalCredit > 0 ? 'PROMO' : 'Linqto Bucks' : toCamalCase(order?.paymentType || '') === 'Cash' ? 'Cash Balance' : toCamelCase(order.paymentType)}</div>
          </Row>
          {!isOrderConfirm &&
            <><Row className='place-order-box__details'>
              <div>Ordered At</div>
              <div>{formatDate(order.createdAt)}</div>
            </Row>
            {order.status !== 'Completed' && order.status !== 'Rejected' && order.status !== 'Canceled' && (
              <Row className='place-order-box__details'>
                <div>Expires At</div>
                <div>{formatDate(order.expiresAt)}</div>
              </Row>
            )}
            </>
          }

          {order.entityName && (
            <Row className='place-order-box__details'>
              <div>Purchase{!isOrderConfirm && 'd'} As </div>
              <div className='max-50'>{order.entityName}</div>
            </Row>
          )}
          {order.riaName && (
            <Row className='place-order-box__details'>
              <div>Executed by</div>
              <div className='max-50'>{order.riaName}</div>
            </Row>
          )}
          {order.paymentType && order.paymentType.toLowerCase() === 'uphold' && (
            <>
              <Row className='place-order-box__details'>
                <div>Uphold Exchange Rate</div>
                <div>{order.upholdExchangeRate}</div>
              </Row>
              <Row className='place-order-box__details'>
                <div>Uphold Account</div>
                <div>{order.upholdCardLabel}</div>
              </Row>
            </>
          )}
          <div className='line'></div>
          <Row className='place-order-box__details'>
            {!isOrderConfirm ? (<div>Investment Amount</div>)
              : (<div>
                Estimated Investment Amount
              </div>)
            }
            <div>{formatDecimal(order.amount)}</div>
          </Row>
          {order.paymentType && order.paymentType.toLowerCase() === 'uphold' && (
            <>
              <Row className='place-order-box__details'>
                <div>Uphold Fee</div>
                <div>{formatDecimal(order.upholdFee)}</div>
              </Row>
              {order.upholdCommission > 0 && <Row className='place-order-box__details'>
                <div>Uphold Commission</div>
                <div>{formatDecimal(order.upholdCommission)}</div>
              </Row>}
            </>
          )}
          {order?.creditsUsed > 0 && <Row className='place-order-box__details'>
            <div>Purchase Credits</div>
            <div>-{formatDecimal(order?.creditsUsed)}</div>
          </Row>}
          {order?.discountAmount > 0 && <Row className='place-order-box__details'>
            <div>Promo Discount</div>
            {order?.discountPercent > 0 && <div className='promo'>-{order?.discountPercent * 100}% ({formatDecimal(order?.discountAmount)})</div>}
            {(!order?.discountPercent && order?.discountAmount > 0) && <div className='promo'>-{formatDecimal(order?.discountAmount)}</div>}
          </Row>}
          <Row className='place-order-box__details'>
            <div>Linqto Fee</div>
            <div>{formatDecimal(order.linqtoFee)}</div>
          </Row>
          {order.linqtoBucksUsed > 0 && <Row className='place-order-box__details'>
            <div>Linqto Bucks</div>
            <div>
                -{formatDecimal(order.linqtoBucksUsed)}
            </div>
          </Row>}
          {order.promotionalCredit > 0 && <Row className='place-order-box__details'>
            <div>Promotional Credit</div>
            <div>
                -{formatDecimal(order.promotionalCredit)}
            </div>
          </Row>}
          <div className='line'></div>
          <Row className='place-order-box__details'>
            <div>
              <b className='heading_7'>{!isOrderConfirm ? 'TOTAL' : 'Estimated Amount Due'}</b>
            </div>
            <div className='heading_7'>{formatDecimal(order.total)}</div>
          </Row>
          <div className='b_16_regular info'>
          *You are purchasing the number of units in the series that represents the equivalent number of shares identified above.
            {isOrderConfirm && ' Your order will execute within 5% of the estimated total or better.' }
          </div>
          {width <= MOBILE_WIDTH_SIZE && <div className='place-order-m-content'>
            {<ProductDocuments documents={documents} />}
          </div>}
          {children}
        </div>
      </Content>
    </Wrapper>
  )
}

export default OrderSection
