import AppleSignin from 'react-apple-signin-auth'
import { images } from 'assets'
import { Mixpanel, Fullstory } from 'utils'

const AppleSignInBtn = ({ copy, onOAuthSuccess, style = '' }) => {
  const handleClick = (props) => {
    Mixpanel.track(`Click Apple OAuth on Sign ${copy === 'Sign in with  Apple' ? 'In' : 'Up'} Page`)
    Fullstory.track(`Click Apple OAuth on Sign ${copy === 'Sign in with  Apple' ? 'In' : 'Up'} Page`)
    props.onClick()
  }

  return (
    <AppleSignin
      authOptions={{
        clientId: 'com.linqto.web.auth',
        scope: 'email name',
        redirectURI: window.location.origin,
        state: 'state',
        nonce: 'nonce',
        usePopup: true
      }}
      uiType='dark'
      noDefaultStyle={false}
      onSuccess={(response) => {
        let userInfo = response?.user?.name
        if (userInfo) {
          localStorage.setItem('appleAuthData', JSON.stringify(response))
        } else {
          const appleAuthData = localStorage.getItem('appleAuthData')
          userInfo = JSON.parse(appleAuthData)?.user?.name
        }
        onOAuthSuccess(response?.authorization.id_token, 'Apple', userInfo?.firstName, userInfo?.lastName)
      }}
      onError={(error) => console.error(error)}
      skipScript={false}
      iconprop={{ style: { marginTop: '10px' } }}
      render={(props) => style === 'icon' ? <a className='link' onClick={() => handleClick(props)}><img src={images['referfriend-apple']} alt={copy} /></a> : <div className='b_16_semibold signup-oath-btn apple-oath-btn' {...props} onClick={() => handleClick(props)}><img
        src={images['apple-logo']} alt={copy}
      />{copy}</div>}
    />
  )
}

export default AppleSignInBtn
