import { useEffect, useState } from 'react'
import qs from 'qs'
import { PageLoading } from 'components'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { signInWithCode } from 'slices/userSlice'
import { images } from 'assets'
import Button from 'components/Global/Button'

const AutoSignIn = () => {
  const [status, setStatus] = useState('init')
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    const code = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (!code.code || code.code.length === 0) {
      setStatus('failed')
    } else {
      dispatch(signInWithCode(code))
        .then(({ meta, payload }) => {
          if (meta.requestStatus === 'fulfilled') {
            history.push('/identity/auto-verification/1')
          } else {
            setStatus('failed')
          }
        })
    }
  }, [location, signInWithCode])

  if (status === 'failed') {
    return (
      <div className='verify'>
        <div className='status-container'>
          <div className='message'>
            <img alt='The link has expired' src={images.failed} />
            <p className='b_18_regular'>The link has expired.</p>
          </div>
          <Button onClick={() => history.push('/')}>Back to Home</Button>
        </div>
      </div>
    )
  }
  return (
    <PageLoading />
  )
}

export default AutoSignIn
