import { useFormContext, Controller } from 'react-hook-form'
import TextInput from 'components/Global/Inputs/TextInput'
import Select from 'components/Global/Inputs/Select'
import MultiSelect from 'components/Global/Inputs/MultiSelect'
import { useSelector } from 'react-redux'
import { allocationOptions, annualIncomeOptions, netWorthOptions } from 'components/data'
import { isWhiteSpace } from 'utils'
import { useEffect, useMemo, useState } from 'react'

const FinancialFields = ({ setValidateEmployment = null, setValidateFinancialProfile = null }) => {
  const { profileLoading, allSectors, userProfile, isAccredited } = useSelector(state => state.profile)
  const [sectorList, setSectorList] = useState([])
  const { control, watch } = useFormContext()

  const optionList = useMemo(() => allSectors || [], [allSectors])
  useEffect(() => {
    const sectors = optionList?.map(s => ({ value: s.sectorId, label: s.name })) || []
    setSectorList(sectors)
  }, [optionList])

  // Only used for returning users with accredited profiles
  if (setValidateEmployment && setValidateFinancialProfile) {
    // validation for empolyment and financial profile - returning accredited users
    const employmentFields = ['occupation', 'employerName']
    const hasValidEmploymentFields = employmentFields.some(f => watch(f) && (watch(f) !== '' || isWhiteSpace(f)))
    const financialProfileFields = ['annualIncome', 'networthExcludingResidence', 'capitalAllocation', 'sectors']
    const hasValidProfileFields = financialProfileFields.some(f => {
      if (f === 'sectors') {
        return watch(f) && (watch(f).length > 0)
      }
      return watch(f) && (watch(f) !== 'Please select')
    })

    useEffect(() => {
      setValidateEmployment(hasValidEmploymentFields)
    }, [hasValidEmploymentFields])

    useEffect(() => {
      setValidateFinancialProfile(hasValidProfileFields)
    }, [hasValidProfileFields])
  }

  return (
    <>
      <div className='heading_7'>Employment Information</div>
      <div className='grid'>
        <div className='column eight sixteen-mobile'>
          <TextInput
            name='occupation'
            label='Occupation'
            ariaLabel='occupation'
            disabled={profileLoading}
          />
        </div>
        <div className='column eight sixteen-mobile'>
          <TextInput
            name='employerName'
            label='Employer Name'
            ariaLabel='employerName'
            disabled={profileLoading}
          />
        </div>
      </div>
      <div className='heading_7'>Financial Profile</div>
      <div className='grid'>
        <div className='column eight sixteen-mobile'>
          <Controller
            control={control}
            name='annualIncome'
            render={({ field }) => (
              <Select
                field={field}
                label='Annual Income'
                name='annualIncome'
                options={Object.values(annualIncomeOptions)}
                ref={null}
                disabled={profileLoading}
                ariaLabel='annualIncome'
              />
            )}
          />
        </div>
        <div className='column eight sixteen-mobile'>
          <Controller
            control={control}
            name='networthExcludingResidence'
            render={({ field }) => (
              <Select
                field={field}
                label='Net Worth '
                name='networthExcludingResidence'
                options={Object.values(netWorthOptions)}
                ref={null}
                disabled={profileLoading}
                ariaLabel='networthExcludingResidence'
                extraLabel='(Excluding primary residence)'
              />
            )}
          />
        </div>
        <div className='column eight sixteen-mobile'>
          <Controller
            control={control}
            name='capitalAllocation'
            render={({ field }) => (
              <Select
                field={field}
                label='How much do you plan to allocate to private equity this year?'
                name='capitalAllocation'
                options={Object.values(allocationOptions)}
                ref={null}
                disabled={profileLoading}
                ariaLabel='capitalAllocation'
              />
            )}
          />
        </div>
        <div className='column eight sixteen-mobile'>
          <Controller
            control={control}
            name='sectors'
            render={({ field }) => (
              <MultiSelect
                name='sectors'
                ariaLabel='sectors'
                field={field}
                label='What sector(s) are you most interested in?'
                placeholder='Please select'
                extraLabel='(Choose all that apply)'
                selectedOptions={isAccredited ? userProfile.sectors : []}
                optionsList={sectorList}
              />
            )}
          />
        </div>
      </div>
    </>
  )
}
export default FinancialFields
