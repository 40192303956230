import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Mixpanel } from 'utils'

const InCompleteProfileSection = () => {
  const { details } = useSelector(state => state.investSlice)
  const history = useHistory()

  const { isSoldOut } = details
  const { isProfileComplete } = details?.user || {}

  const goToProfile = () => {
    Mixpanel.track('Click Incomplete Profile Link')
    history.push('/profile?redirect=place-order')
  }
  if (isProfileComplete || isSoldOut) return null
  return (
    <div className='place-order-copy'>
      To place an order please complete your investor profile.{' '}
      <a className='inline-text-link' onClick={goToProfile} data-testid='completeProfileLink'>Complete Profile</a></div>)
}

export default InCompleteProfileSection
