import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Mixpanel, formatWholeNumber } from 'utils'

const InvestDetailSellButton = () => {
  const {
    details: { urlName, name, atsPoolEnabled }, ownedShares, canSellShares
  } = useSelector((state) => state.investSlice)
  const history = useHistory()

  const handelSell = () => {
    Mixpanel.track('Click Sell on Product Page', { 'Company Name': name })
    if (atsPoolEnabled) {
      history.push(`/sell-order/create/${urlName}`)
    }
  }

  if (canSellShares && ownedShares > 0 && atsPoolEnabled) {
    return (
      <div className='invest-details-sell-container'>
        <div className='box invest-detail-box invest-order-box'>
          <div>Shares Owned</div>
          <div>{formatWholeNumber(ownedShares)}</div>
          <a className='link primary' onClick={handelSell}>
            Sell
          </a>
        </div>
      </div>
    )
  }
  return null
}
export default InvestDetailSellButton
