import React, { useEffect, useState } from 'react'
import Modal from '../Global/Modal'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import Button from '../Global/Button'
import Input from '../Global/InputField'
import { PageLoading } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { getSignInMFAs, sendSignInMFA, verifySignInMFA } from 'slices/userSlice'

const typeName = {
  TOTP: 'Authenticator App',
  SMS: 'SMS Text Message',
  EMAIL: 'Email Authentication'
}

let interval

const MfaModal = ({ hideModal }) => {
  const params = useParams()
  const location = useLocation()
  const [currentMfa, setCurrentMfa] = useState(null)
  const [value, setValue] = useState('')
  const [formErr, setFormErr] = useState('')
  const [sentTimeStamp, setSentTimeStamp] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [touched, setTouched] = useState(false)
  const [sendErr, setSendErr] = useState('')
  const { pageLoading, mfas, canImpersonate } = useSelector(state => state.userSlice)
  const { userMfaId } = params
  const dispatch = useDispatch()
  const history = useHistory()
  const handleChooseMfa = async (m) => {
    if (m?.type !== currentMfa?.type) {
      setCurrentMfa(m)
    }
    history.push(`/signin/mfas/${m?.type?.toLowerCase()}/${m?.userMfaId}`)
  }

  const sendMfa = async (m) => {
    const response = await dispatch(sendSignInMFA(m.userMfaId))
    if (response?.payload?.err) {
      setSendErr(response.payload.err)
    }
  }

  useEffect(() => {
    (async () => {
      history.push('/signin/mfas')
      await dispatch(getSignInMFAs())
    })()
  }, [])

  useEffect(() => {
    return () => {
      clearInterval(interval)
      interval = 0
    }
  }, [])

  const handleSendAgain = async () => {
    clearInterval(interval)
    const date = new Date()
    setSentTimeStamp(date.getTime())
    setCurrentTime(date.getTime())
    const response = await dispatch(sendSignInMFA(userMfaId))
    if (response && response.err) {
      setSendErr(response.err)
    }
    interval = setInterval(() => {
      const newDate = new Date()
      setCurrentTime(newDate.getTime())
    }, 1000)
  }

  const renderResend = () => {
    const seconds = Math.floor((currentTime - sentTimeStamp) / 1000)
    if (sentTimeStamp === 0 || seconds > 30) {
      return (
        <span className='b_18_semibold' onClick={() => handleSendAgain()}>Click to send again.</span>
      )
    } else if (seconds < 30 || seconds === 30) {
      return (
        <span className='time-counter b_18_semibold'>resent {Math.floor((currentTime - sentTimeStamp) / 1000)}s ago</span>
      )
    }
  }

  const handleChooseOther = () => {
    setCurrentMfa(null)
    setFormErr('')
    setValue('')
    history.push('/signin/mfas')
  }

  useEffect(() => {
    if (userMfaId && mfas) {
      const m = mfas?.find(m => m.userMfaId === parseInt(userMfaId))
      if (m) {
        handleChooseMfa(m)
        if (m.type === 'EMAIL' || m.type === 'SMS') {
          sendMfa(m)
        }
      }
    }
  }, [userMfaId, mfas])

  const renderContent = () => {
    if (sendErr.length > 0) {
      return <div className='security mfa'>
        <div className='b_18_regular' style={{ textAlign: 'center' }}>{sendErr}</div>
      </div>
    }
    if (location?.pathname === '/signin/mfas' && mfas?.length > 1) {
      return (
        <div className='btn-group stacked'>
          {mfas.map((m) => (
            <Button
              customClass='full-width'
              key={m.userMfaId}
              onClick={ () => handleChooseMfa(m)}
              data-testid={`mfa-${m.type}`}>
              {typeName[m.type]}
            </Button>
          ))}
        </div>
      )
    } else if (location?.pathname === '/signin/mfas' && mfas?.length === 1) {
      handleChooseMfa(mfas[0])
    } else if (currentMfa && Object.keys(currentMfa).length > 0) {
      return (
        <div className='security mfa mfa-onboarding'>
          <form onSubmit={(e) => handleOtpSubmit(e)}>
            <label htmlFor='value'>
              {currentMfa.type === 'TOTP' && 'Please enter the six digit one-time passcode from your Authenticator App.'}
              {(currentMfa.type === 'EMAIL' || currentMfa.type === 'SMS') && `Please enter the six digit one-time passcode sent to ${currentMfa.value}`}
            </label>
            <Input
              type='tel'
              label=''
              input={{ name: 'value', value: value }}
              meta={{
                touched: touched,
                error: formErr
              }}
              onChange={(e) => {
                setValue(e.target.value)
                setFormErr('')
              }}
              onFocus={() => {
                setTouched(true)
              }}
              disabled={pageLoading}
              maxLength='6'
              autoFocus
              autoComplete='one-time-code'
              className='no-label'
              ariaLabel='otp-code-input'
            />

            <div className='btn-group centered'>
              <Button
                type='submit'
                onClick={(e) => handleOtpSubmit(e)}
                disabled={pageLoading}
                loading={pageLoading}
              >
                  Confirm
              </Button>
            </div>
          </form>
          {currentMfa.type === 'EMAIL' && <div className='note'>Did not receive the code? {renderResend()}</div>}
          {mfas.length > 1 && <div className='note'><span onClick={() => handleChooseOther()}>Want to use a different authentication method?</span></div>}
        </div>
      )
    }
  }

  const handleOtpSubmit = async (e) => {
    e.preventDefault()
    const userMfaId = currentMfa.userMfaId
    if (userMfaId && value.length === 6) {
      const response = await dispatch(verifySignInMFA({ userMfaId, otp: value }))
      if (response.payload === 'success') {
        hideModal()
        if (canImpersonate) {
          history.push('/ria-dashboard')
        } else if (localStorage.getItem('ria-route')) {
          history.push(localStorage.getItem('ria-route'))
        } else if (localStorage.getItem('prev-route')) {
          history.push(localStorage.getItem('prev-route'))
          localStorage.removeItem('prev-route')
        } else {
          history.push('/products')
        }
      } else if (response.payload?.err) {
        setFormErr(response.payload.err)
      }
    } else if (value.length < 6 || value.length > 6) {
      setFormErr('The one-time passcode should be 6 digits.')
    }
  }
  return (
    <Modal innerStyle='mfa-modal' modalHeader={(currentMfa && currentMfa.type) ? typeName[currentMfa.type] : 'Authentication Method'} hideModal={() => hideModal(true)} crossToClose>
      {pageLoading ? <PageLoading /> : renderContent()}
    </Modal>
  )
}

export default MfaModal
