const ProfileTabContainer = ({ onChange, tab }) => {
  const handleChange = (type) => {
    onChange(type)
    window.history.replaceState(null, null, type === 'details' ? '/profile' : `?tab=${type}`)
  }

  return (
    <div className='page-tabs border-old'>
      <div className={`tab bottom heading_7 ${tab === 'details' && 'active connectedCobalt'}`} onClick={() => handleChange('details')}>Profile Details</div>
      <div className={`tab bottom heading_7 ${tab === 'brokerage' && 'active connectedCobalt'}`} onClick={() => handleChange('brokerage')}>Brokerage</div>
      <div className={`tab bottom heading_7 ${tab === 'entity' && 'active connectedCobalt'}`} onClick={() => handleChange('entity')}>Entity</div>
    </div>
  )
}

export default ProfileTabContainer
