import { useSelector } from 'react-redux'
import { Mixpanel, getDomain } from 'utils'

const SellOrderHeader = ({ title, showHelp }) => {
  const { companyName, companyIconUrl } = useSelector(
    (state) => state.sellOrder
  )
  return (
    <>
      <div className='sell-order-header sell-order-header'>
        <h1>{title}</h1>
        <div className='sell-order-company-name'>
          {companyIconUrl && <img src={companyIconUrl} alt={companyName} />}
          <div className='company-name'>{companyName}</div>
        </div>
      </div>
      { showHelp &&
        <div className='b_18_regular'>
         Want to learn more? <a
            className='link primary'
            href={getDomain('/sell-order/how-it-works/', true)}
            target='_blank'
            rel='noreferrer'
            onClick={() => Mixpanel.track('Click See How It Works on Create Sell Order Page') }
          >See How it Works</a>
        </div>
      }
    </>
  )
}

export default SellOrderHeader
